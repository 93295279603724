import Vue from 'vue'
import App from '../App.vue'
import router from './router'
import store from '../store'
import { Popup, Toast } from 'vant'
import request from '@utils/request.js'
import http from '@utils/http.js'
import popup from '@components/popup/index.js'
import {
    platFormXxLogin
} from '@apis/feitian_api.js'

import '@assets/css/feitian.less'

import VueLazyload from 'vue-lazyload'
// 配置项
Vue.use(VueLazyload, {
  preLoad: 1,
//   error: 'https://img2.baidu.com/it/u=3884358878,1708776640&fm=26&fmt=auto&gp=0.jpg',
//   loading: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.mp.sohu.com%2Fupload%2F20170601%2Faf68bce89ac945e7ad00da688a25fb08.png&refer=http%3A%2F%2Fimg.mp.sohu.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1630244172&t=458971e2668cd20262fa13db0ab196d8',
  attempt: 3,
})

Vue.use(Popup)
Vue.use(Toast)
Vue.prototype.$http = request
Vue.prototype.$http_dengta = http.dengta
Vue.prototype.$popup = popup
Vue.config.ignoredElements = ['wx-open-launch-weapp']

Vue.config.productionTip = false

// 路由进入之前拦截
router.beforeEach((to, from, next) => {
    const meta = to.meta || {}
    // 设置页面标题
    if (meta.title) document.title = meta.title
    if (!localStorage.getItem('platFrom_token')) {
      localStorage.setItem('platFrom_token', 'fake_token')
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4a26743df30a9fe5&redirect_uri=${encodeURIComponent(location.href.replace(/(code=[\s\S][^&]+)/, ''))}&response_type=code&scope=snsapi_userinfo#wechat_redirect`
      return
    }
    if (to.query && to.query.code && localStorage.getItem('platFrom_token') === 'fake_token') {
        platFormXxLogin(to.query.code).then(res => {
            if (res.resultCode && res.resultCode.code === 0) {
                localStorage.setItem('platFrom_token', res.data.accessToken)
            }
            next()
        }).catch(() => {
            next()
        })
        return
    }
    // loading
    Toast.loading({message:'loading...', duration: 0})
    next()
})
// 页面进入之前拦截
Vue.mixin({
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.init ? vm.init(() => Toast.clear()) : Toast.clear()
        })
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
