<template>
  <div class="com-video-player video-box">
    <video-player
      ref="videoPlayer"
      :options="options"
      :playsinline="true"
      @play="onVideoPlay"
      @pause="onVideoPause"
      @timeupdate="onTimeUpdate()"
      @fullscreenchange="fullscreenchange"
      @ended="onVideoEnded"/>
    <button class="vjs-compact-play-btn" @click="videoPlayerCompactPlay" v-show="showVideoPlayBtn"></button>
    <div
      class="fullscreen-tip"
      v-show="showFullscreenTip"
      @click="onEnterFullscreen"
      v-if="needFullscreen">
      <img src="./images/fullscreen_tip.png"/>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import videoPlayer from './vue-video-player'
export default {
  name: 'VideoPlayer',
  props: {
    options: {type: Object},
    needFullscreen: {type: Boolean}
  },
  components: {
    videoPlayer
  },
  data () {
    return {
      showVideoPlayBtn: true,
      showFullscreenTip: false,
    }
  },
  computed: {
    videoPlayer () {
      return this.$refs.videoPlayer.player
    }
  },
  mounted () {
    this.$on('pause', () => {
      this.videoPlayer.pause()
    })
    setTimeout(() => {
      this.$emit('ready', this.videoPlayer)
    }, 300)
  },
  methods: {
    onVideoPlay () {
      this.showVideoPlayBtn = false
      this.$emit('play')
      this.onVideoStatusChange()
    },
    onVideoPause () {
      this.showVideoPlayBtn = true
      this.$emit('pause')
      this.onVideoStatusChange()
    },
    videoPlayerCompactPlay () {
      this.videoPlayer.play()
    },
    onVideoEnded () {
      this.$emit('ended')
    },
    // 显示全屏提示
    onVideoStatusChange () {
      this.showFullscreenTip = true
      let timer = setTimeout(() => {
        this.showFullscreenTip = false
        clearTimeout(timer)
      }, 5000)
    },
    fullscreenchange (val) {
      this.$emit('fullscreenchange', val)
    },
    // 全屏播放视频
    onEnterFullscreen () {
      this.videoPlayer.requestFullscreen()
    },

    onTimeUpdate () {
      this.$emit('timeupdate', this.videoPlayer.currentTime())
    }
  }
}
</script>

<style lang="less" scoped>
// video css setting
video {
  width: 100%;
}
.video-box {
  position: relative;
  width: 100%;
  height: 420px;
  background-color: #000;
  z-index:2;
}
.video-box /deep/ .video-player {
  position: relative;
  width: 100%;
  height: 100%;
}
.video-box /deep/ .video-js {
  font-size: 20px;
  width: 100%;
  height: 100%;
  .vjs-tech{
    z-index:2 !important;
  }
  .vjs-poster{
    z-index:4;
  }
  .vjs-control-bar{
    z-index: 6;
  }
}
.video-box /deep/ .video-js .vjs-big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  line-height: 1.7em;
  height: 1.7em;
  width: 1.7em;
  font-size: 4em;
}
.video-box /deep/ .vjs-control-bar .vjs-icon-placeholder:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}

.video-box /deep/ .vjs-big-play-button {
  display: none;
}

.fullscreen-tip {
  position: absolute;
  bottom: 2%;
  right: 2%;
  width: 200px;
  border-radius: 30px;
  & > img {
    width: 100%;
  }
}
.video-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .vjs-compact-play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    border: 3px solid #fff;
    border-radius: 100%;
    background-color: rgba(43, 51, 63, 0.7);
    z-index: 10;
    &:before {
      position: relative;
      top: 2px;
      font-family: VideoJS;
      font-size: 40px;
      content: "\F101";
      color: #fff;
    }
  }
}
</style>
