<template>
  <div class="video-wrap">
    <Video
      v-if="isShowVideo"
      :videoSource="videoOptions.videoSource"
      :posterImg="videoOptions.posterImg"
      :type="videoOptions.type"
      @videoFirstPlay="onVideoFirstPlay"
      @timeupdate="onVideoTimeUpdate"
      @ready="onVideoReady"
      @ended="onVideoEnd"
      @pause="onVideoPause"
      @fullscreenchange="onVideoFullScreenChange"
    />
    <div v-if="isShowVideoCover" class="video-cover" @click="$emit('playVideo')">
      <img src="./images/play_btn.png" alt="">
    </div>
  </div>
</template>

<script>
import Video from '@/components/video/video.vue'

export default {
  components: {
    Video
  },
  props: {
    isShowVideo: Boolean,
    isShowVideoCover: Boolean,
    videoOptions: Object,
  },
  data() {
    return {
      videoPlayer: null,
      duration: 0,
      currentTime: 0,
      hasDragProgress: false
    }
  },
  methods: {
    onVideoFirstPlay($event) {
      this.$emit('videoFirstPlay', $event)
    },
    onVideoTimeUpdate($event) {
      this.$emit('timeupdate', $event)
    },
    onVideoReady($event) {
      this.videoPlayer = $event
      this.duration = this.videoPlayer.cache_.duration
      this.$emit('ready', $event)
    },
    onVideoEnd($event) {
      this.$emit('ended', $event)
    },
    onVideoPause($event) {
      this.$emit('pause', $event)
    },
    onVideoFullScreenChange($event) {
      this.$emit('fullscreenchange', $event)
    }
  }
}
</script>

<style lang="less" scoped>
  .video-wrap {
    position: relative;
    height: 420px;
    /deep/ .video {
      border-radius: 0;
      height: 420px !important;
    }
    .video-cover {
      pointer-events: all;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      width: 100%;
      height: 420px;
      background: rgba(0, 0, 0, 0.5);
      img {
        width: 120px;
      }
    }
  }
</style>