<template>
  <div class="recommend">
    <div class="recommend-name flex-start"><div class="line"></div>{{list.name}}</div>
    <div class="recommend-list" :class="[direction]">
      <div class="course" v-for="(course, index) in list.courses || list.channels" :key="index" @click="toBuyPage(course.linkUrl)">
        <WxOpenLaunchWeapp v-if="direction === 'vertical'" :username="wxAppId" :path="`ftCourse/pages/course/v0/index?courseId=${course.gubiCourseIds[0]}&gChannel=${course.channelCode}`" :wxBtnStyle="wxBtnStyle" :wxBtnText="wxBtnText" class="to-select-course">
        </WxOpenLaunchWeapp>
        <img :src="course.imgUrl" alt="" class="course-bg">
        <div class="detail">
          <div>
            <div class="course-name">{{course.name}}</div>
            <div class="course-desc">{{course.desc}}</div>
            <div class="course-score" v-if="course.score">推荐值：{{course.score}}%</div>
          </div>
          <div class="course-price">¥<div class="price">{{(course.price / 100).toFixed(2)}}</div><div class="num" v-if="course.chapters">/{{course.chapters}}</div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WxOpenLaunchWeapp from '@components/WxOpenLaunchWeapp'
export default {
  components: {
    WxOpenLaunchWeapp
  },
  props: {
    list: {
      type: Object,
      default: () => {
        return {}
      }
    },
    direction: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      wxAppId: 'gh_0a3dbde56d7b',
      wxBtnStyle: '',
      wxBtnText: ''
    }
  },
  mounted () {
    const winWidth = document.body.clientWidth
    this.wxBtnStyle = `.weapp-btn {
      display: block; width: ${(winWidth * 750) / 750}px; height: ${(winWidth * 206) / 750}px;
    }`
  },
  methods: {
    toBuyPage(linkUrl) {
      if (this.direction === 'vertical') return
      location.href = linkUrl
    }
  }
}
</script>
<style lang='less' scoped>
  .recommend {
    width: 100%;
    overflow-x: hidden;

    &-name {
      margin-bottom: 48px;
      padding-left: 40px;
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;
      color: #1F2329;

      .line {
        margin-right: 20px;
        width: 8px;
        height: 32px;
        border-radius: 200px;
        background: #FF652F;
      }
    }

    &-list {
      padding: 0 0 64px 40px;

      &.horizon {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .course {
          margin-bottom: 20px;

          &:nth-child(2n + 1){
            margin-right: 26px;
          }

          &-bg {
            margin-bottom: 24px;
          }
        }
      }

      &.vertical {
        .course {
          display: flex;
          align-items: center;
          height: 206px;

          &-bg {
            margin-right: 26px;
            width: 206px;
            height: 206px;
          }

          .detail {
            box-sizing: border-box;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: space-between;
            padding: 8px 0;
            height: 100%;
          }

          &-price {
            .price {
              font-size: 36px;
              line-height: 36px;
            }

            .num {
              display: inline-block;
              margin-left: 8px;
              font-size: 24px;
              line-height: 24px;
              color: #272D38;
              font-weight: bold;
            }
          }

          & + .course {
            margin-top: 32px;
          }
        }
      }

      .course {
        position: relative;

        &-bg {
          flex-shrink: 0;
          width: 322px;
          height: 322px;
          border-radius: 32px;
          background: #0ff;
        }

        &-name {
          margin-bottom: 16px;
          font-size: 32px;
          font-weight: bold;
          line-height: 32px;
          color: #1F2329;
        }

        &-desc {
          margin-bottom: 20px;
          font-size: 24px;
          line-height: 24px;
          color: #555B66;
        }

        &-score {
          font-size: 20px;
          line-height: 20px;
          color: #FF652F;
        }

        &-price {
          font-size: 24px;
          line-height: 24px;
          color: #FF443D;

          .price {
            display: inline-block;
            margin-left: 6px;
            font-size: 48px;
            line-height: 48px;
            font-weight: bold;
          }
        }
      }
    }

    .to-select-course {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
