<template>
    <div class="ft-fail">
        <div class="title">退费提醒</div>
        <div class="content">亲爱的家长您好，检测到您的手机号已在其他平台购买过<span class="course-name">{{ courseName }}</span>，可更换手机号或为您办理退费退课。</div>
        <div class="tips">注：退还费用将发至您的奖学金账户内，可提现至微信。</div>
        <div class="foot">
            <button class="btn cancel" @click="$emit('on-click', {code:'cancel'})">退费退课</button>
            <button class="btn submit" @click="$emit('on-click', {code:'submit'})">更换手机</button>
        </div>
    </div>
</template>

<script>
import courseName from '../refundPrice/index.js'

export default {
    props: {
        courseId: {
            type: [Number, String],
            default: () => ''
        }
    },
    computed: {
        courseName() {
            return this.courseId ? courseName[this.courseId] : '没有该课程'
        }
    },
}
</script>

<style lang="less" scoped>
.ft-fail {
    text-align: justify;
    padding: 40px;
    .title {
        line-height: 1;
        color: #292118;
        font-size: 36px;
        font-weight: 600;
        text-align: center;
    }
    .content {
        margin-top: 40px;
    }
    .course-name {
        color: #7559ff;
    }
    .tips {
        color: #9ba1ad;
        font-size: 24px;
        margin-top: 20px;
    }
    .foot {
        display: flex;
        margin-top: 66px;
        .btn {
            flex: 1;
            height: 80px;
            font-size: 28px;
            font-weight: 500;
            border: 0;
            border-radius: 40px;
        }
        .btn+ .btn {
            margin-left: 26px;
        }
    }
    .cancel {
        background: #f4f4f4;
    }
    .submit {
        background: #7559ff;
        color: #fff;
    }
}
</style>