<template>
  <div class="wx-open-btn">
    <wx-open-launch-weapp :username="username" :path="path" class="wx-open-launch-weapp">
      <script type="text/wxtag-template">
        <style>{{wxBtnStyle}}</style>
        <div class="weapp-btn">{{wxBtnText}}</div>
      </script>
    </wx-open-launch-weapp>
  </div>
</template>

<script>
export default {
  props: {
    username: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    wxBtnStyle: {
      type: String,
      default: ''
    },
    wxBtnText: {
      type: String,
      default: ''
    }
  },
  mounted() {
    console.log(this.wxBtnStyle, 'wxsss')
  }
}
</script>
<style lang='less' scoped>

</style>
