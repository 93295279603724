<template>
  <div class="common-problem box-border">
    <div v-for="(item, index) in config" :key="index" class="section">
      <div class="question">{{item.question}}</div>
      <div class="answer">{{item.answer}}</div>
    </div>
  </div>
</template>

<script>
import {config} from './config.js'
export default {
  components: {

  },
  data () {
    return {
      config: config
    }
  },
  mounted () {
    console.log(config)
  },
  methods: {

  }
}
</script>
<style lang='less' scoped>
  .common-problem {
    padding: 32px 0;
    min-height: 100vh;
    background: #fff;

    .section {
      padding: 0 40px;

      .question,
      .answer {
        font-size: 24px;
        line-height: 52px;
        color: #272D38;
      }

      .question {
        font-weight: bold;
      }

      & + .section {
        margin-top: 80px;
      }
    }
  }
</style>
