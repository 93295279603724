import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@pages/index.vue')
    },
    {
        path: '/common/bindAddress/:actId',
        name: 'Common.BindAddress',
        component: () => import('@pages/common/bindAddress/index.vue'),
        meta: {
            title: '填写收货地址'
        }
    }, {
        path: '/common/guidePage/:qrcode',
        name: 'Common.GuidePage',
        component: () => import('@pages/common/guidePage/index.vue'),
        meta: {
            title: '关注公众号'
        }
    },
    {
        path: '/test',
        name: 'Tets',
        component: () => import('@pages/test.vue'),
        meta: {
            title: '测试地址'
        }
    },
    {
        path: '*',
        component: () => import('@pages/notFound.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
