import Vue from 'vue'
import { Picker } from 'vant'

/**
 * 选择数据api
 * @param {String} params.value // 复选数据
 * @param {String} params.title // 弹窗标题
 * @param {Array} params.columns // 数据 => [{label:'名称', value:'值'}]
 */
export default (params={}) => {
    return new Promise((resolve) => {
        const Instance = new Vue({
            components: {
                'van-picker': Picker,
            },
            data() {
                return {
                    show: true,
                }
            },
            render(h) {
                // 默认选中数据
                const defaultIndex = params.value ? params.columns.findIndex(item => item.value === params.value) : ''

                return h('van-popup', {
                    attrs: {
                        value: this.show,
                        round: true,
                        position: 'bottom',
                        'close-on-click-overlay': false,
                        'transition-appear': true,
                        'safe-area-inset-bottom': true,
                    },
                }, [
                    h('van-picker', {
                        attrs: {
                            title: params.title || '请选择数据',
                            'value-key': 'label',
                            'show-toolbar': true,
                            columns: params.columns || [],
                            'default-index': defaultIndex,
                        },
                        on: {
                            confirm: e => {
                                resolve(e)
                                this.remove()
                            },
                            cancel: this.remove
                        }
                    })
                ])
            },
            methods: {
                remove() {
                    this.show = false
                    setTimeout(() => this.destroy(), 300)
                },
                destroy() {
                    this.$destroy()
                    if (this.$el) document.body.removeChild(this.$el)
                }
            }
        })

        const component = Instance.$mount()
        document.body.appendChild(component.$el)
    })
}