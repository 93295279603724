import request from './request.js'

export default {
    dengta: {
        get(url, data, config) {
            return request.get(process.env.VUE_APP_DENGTA_API + url, data, config)
        },
        post(url, data, config) {
            return request.post(process.env.VUE_APP_DENGTA_API + url, data, config)
        }
    },
    gubi: {
        get(url, data, config) {
            return request.get(process.env.VUE_APP_GUBI_API + url, data, config)
        },
        post(url, data, config) {
            return request.post(process.env.VUE_APP_GUBI_API + url, data, config)
        }
    },
    center: {
        get(url, data, config) {
            return request.get(process.env.VUE_APP_CENTER_API + url, data, config)
        },
        post(url, data, config) {
            return request.post(process.env.VUE_APP_CENTER_API + url, data, config)
        }
    }
}