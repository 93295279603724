<template>
  <div class="video" ref="video" :class="{'hide-video-play-btn': !isVideoPlayed}">
    <VideoPlayer @play="onVideoPlay" @pause="onVideoPause" :options="videoOptions" @timeupdate="$emit('timeupdate', $event)"
    @ready="$emit('ready', $event)" @fullscreenchange="fullscreenchange" @ended="$emit('ended')"/>
  </div>
</template>
<script>
import VideoPlayer from "./video-player";

export default {
  components: { VideoPlayer },
  data() {
    return {
      isVideoPlayed: false,
      // 视频1的选项配置
      videoOptions: {
        sources: [
          {
            type: "video/mp4", // 格式
            src: "", // 地址
          },
        ],
        poster: "",
      },
      playDurationTime:0 ,  // 总共播放持续时间
      startTime : 0,
      timer: null,
    };
  },
  props: ["posterImg", "videoSource", "type"],
  created() {
    this.initPlayer();
  },
  mixins: [],
  mounted() {
    this.judgeSize()
  },
  computed: {
    

  },
  destroyed(){
    clearInterval(this.timer)
  },
  methods: {
    initPlayer() {
      this.videoOptions.poster = this.posterImg || 'http://static2.61info.com/service/imgs/feitian/video/video_bg.png';
      this.videoOptions.sources[0].src = this.videoSource;
      this.videoOptions.sources[0].type = this.type || "video/mp4";
    },
    judgeSize(){
      this.$refs.video.style.height = this.$refs.video.getBoundingClientRect().width * 275/486 + 'px'  
    },
    onVideoPlay() {
      if (!this.isVideoPlayed) {
        this.isVideoPlayed = true;
        this.$emit("videoFirstPlay");
      }
      this.$emit("videoPlay");
      this.startTime = this.getCurrentTime()
      this.startEmitDurationTimeInterval()
    },
    getCurrentTime(){
      return  parseInt(new Date().getTime()/1000)
    },
    onVideoPause(){
      this.getAndEmitDurationTime()
      this.stopEmitDurationTimeInterval()
      this.$emit('pause')
    },
    computedPlayDurationTime(){
      this.playDurationTime += (this.getCurrentTime() - this.startTime)
      this.startTime = this.getCurrentTime()
    },
    getAndEmitDurationTime(){
      this.computedPlayDurationTime()
      this.$emit('durationTimeChange',this.playDurationTime)
    },
    startEmitDurationTimeInterval(){
      this.timer = setInterval(()=>{
        this.getAndEmitDurationTime()
      },2000)
    },
    stopEmitDurationTimeInterval(){
      clearInterval(this.timer)
    },
    fullscreenchange (val) {
      this.$emit('fullscreenchange', val)
    }
  },
};
</script>
<style lang="less" scoped>
.video {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  width: 100%;
}
// 用于隐藏视频的播放按钮
.hide-video-play-btn {
  /deep/ .vjs-compact-play-btn {
    display: none;
  }
}
 /deep/ .video-box,
 /deep/ .vjs-poster,
 /deep/ .video-js{
   background-color: transparent;
 }
// 视频封面图设为和视频一样大小
/deep/ .video-box .video-js .vjs-poster {
  background-size: 100% 100%;
}
</style>