export const courseConfig = {
  10101: {
    pageName: '中华历史故事看课页',
    channel: '104700111',
    gubiChannel: '127676',
  },
  10103: {
    pageName: '讲给孩子的成语故事看课页',
    channel: '104700109',
    gubiChannel: '127674',
  },
  10105: {
    pageName: '100节唐诗国学看课页',
    channel: '104700110',
    gubiChannel: '127675',
  },
  10092: {
    pageName: '宇宙探秘之旅看课页',
    channel: '104700112',
    gubiChannel: '127677',
  },
  10082: {
    pageName: '少儿美术看课页',
    channel: '104700108',
    gubiChannel: '127673',
  },
}

export const courseConfigTest = {
  10079: {
    pageName: '艺术鉴赏',
    channel: '',
    gubiChannel: '122848',
  },
  10076: {
    pageName: '生活创想',
    channel: '',
    gubiChannel: '122847',
  },
  10071: {
    pageName: '角色创想',
    channel: '',
    gubiChannel: '122846',
  },
  10091: {
    pageName: '讲给孩子的希腊故事神话',
    channel: '',
    gubiChannel: '122845',
  },
}
