<template>
  <div class="share-poster flex-center" v-if="showPopup">
    <!-- <popup :value="showPopup"> -->
    <div class="main flex-center">
      <img src="./images/close_btn.png" alt="" class="clost-btn" @click="$parent.showPopup = false">
      <div class="tips1">长按海报分享给好友或班级群</div>
      <div class="tips2">邀请{{inviteInfo.fissionFinishCount - inviteInfo.inviteCount}}位好友扫码报名即可获得全部内容</div>
      <div class="poster" ref="posterWrapper" v-if="!posterWrapperUrl">
        <img :src="posterBg" alt="" class="poster-bg" @load="posterBgLoad = true" />
        <img :src="headUrl || defaultHeaderUrl" alt="" class="poster-header" @load="headerUrlLoad = true" />
        <img :src="qrcodeUrl" alt="" class="poster-qrcode" @load="qrcodeUrlLoad = true" @error="uploadError"/>
      </div>
      <div class="poster" v-else><img :src="posterWrapperUrl" alt="" class="poster-bg" /></div>
    </div>
    <!-- </popup> -->
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
// import popup from '@components/popup/index.vue'
export default {
  components: {
    // popup
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    posterBg: {
      type: String,
      default: ''
    },
    headUrl: {
      type: String,
      default: ''
    },
    qrcodeUrl: {
      type: String,
      default: ''
    },
    inviteInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    canInit() {
      return this.showPopup && this.posterBg && (this.headUrl || this.defaultHeaderUrl) && this.qrcodeUrl && this.posterBgLoad && this.headerUrlLoad && this.qrcodeUrlLoad
    }
  },
  watch: {
    canInit(value) {
      if(value) {
        this.$nextTick(() => {
          this.posterWrapperInit()
        })
      }
    }
  },
  data () {
    return {
      posterWrapperUrl: '',
      defaultHeaderUrl: require('@assets/imgs/logo/gubi.png'),
      posterBgLoad: false,
      headerUrlLoad: false,
      qrcodeUrlLoad: false,
    }
  },
  mounted () {
  },
  methods: {
    posterWrapperInit() {
      if (this.posterWrapperUrl) return
      console.log('poster start 11111111')
      html2canvas(this.$refs.posterWrapper, {
        backgroundColor: null,
        scrollX: 0,
        scrollY: 0, 
        allowTaint: true,
        useCORS: true // 允许图片跨域
      }).then((canvas) => {
        console.log('poster end 11111111')
        this.posterWrapperUrl = canvas.toDataURL("image/png");
        this.$toast.clear()
      })
    },
    uploadError() {
      console.log('qrcodeUrl error')
    }
  }
}
</script>
<style lang='less' scoped>
  .share-poster {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.6);
    // /deep/.van-popup {
    //   background: transparent;
    //   overflow: visible;
    // }

    .main {
      flex-direction: column;
      position: relative;
    }

    .clost-btn {
      position: absolute;
      top: -62px;
      right: -40px;
      width: 48px;
      height: 48px;
    }

    .tips1 {
      margin-bottom: 24px;
      font-size: 40px;
      font-weight: bold;
      line-height: 40px;
      text-align: center;
      color: #fff;
    }

    .tips2 {
      margin-bottom: 32px;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      color: #fff;
    }

    .poster {
      margin: 0 auto;
      position: relative;
      width: 576px;

      .poster-bg {
        width: 576px;
      }

      .poster-header {
        position: absolute;
        top: 24px;
        left: 20px;
        width: 78px;
        height: 78px;
        border-radius: 50%;
      }

      .poster-qrcode {
        position: absolute;
        bottom: 32px;
        right: 28px;
        width: 130px;
        height: 130px;
      }
    }
  }
</style>
