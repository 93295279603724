export const config = [
  {
    question: '1、如何拼团？',
    answer: '可发起或参与团购，开团完成后，可以将分享海报分享给好友，凑足拼团人数视为课程购买成功。即可全部解锁课程。'
  },
  {
    question: '2、发起的拼团不成功，会退款么？',
    answer: '不会，可通过咕比APP或者咕比启蒙官方账号联系客服申请退款。'
  },
  {
    question: '3、购课成功后可以退款么？',
    answer: '本课程为虚拟商品，联系客服退款时，需截图证明未观看所有的课程内容。若已观看，则不予退款，还望各位理解。'
  },
  {
    question: '4、如何观看课程？',
    answer: '关注“趣味素质课堂”公众号，点击底部菜单栏，我的课，即可看课。'
  },
  {
    question: '5、购买后的课程可以分享给他人看么？',
    answer: '不可以，课程已与您的购课手机号所绑定，不可以转移到他人的账号中。请确认购课手机号再观看课程。'
  }
]