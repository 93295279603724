<template>
  <div class="my-order">
    <ul class="tabs d-flex box-border">
      <li class="tabs-item" v-for="(tab, index) in tabList" :key="index" :class="{active: tabIndex === index}" @click="changeTab(index, tab.type)">{{tab.txt}}</li>
    </ul>
    <orderlist
      v-if="orderInfo && orderInfo.length > 0"
      :orderInfo="orderInfo"
    />
    <div class="no-course flex-center flex-column" v-if="orderInfo && orderInfo.length < 1">
      <img src="./images/box.png" alt="" class="box">
      <div class="tips">暂时没有相关订单</div>
    </div>
  </div>
</template>

<script>
import orderlist from './components/orderlist'
import WXShareMix from '@mixins/wx_share_mix.js'
import {
  getOrderInfo,
} from '@apis/feitian_api.js'
export default {
  mixins: [WXShareMix],
  components: {
    orderlist
  },
  data () {
    return {
      // all全部查询 lock未解锁查询 unlock解锁查询 refund退款查询
      tabList: [
        { type: 'ALL', txt: '全部订单' },
        { type: 'LOCK', txt: '拼团中' },
        { type: 'UNLOCK', txt: '已完成' },
        { type: 'REFUND', txt: '已退款' },
      ],
      tabIndex: 0,
      orderInfo: null
    }
  },
  mounted () {
    this.initShare()
    this.forbidShare()
    this._getOrderInfo('ALL')
  },
  methods: {
    initShare () {
      let host = window.location.protocol + '//' + window.location.host
      this.initWXShare({
        imgUrl: 'http://test.static2.61info.cn/service/imgs/groupPurchase/act/act20210618/share/img4.png?time=123456',
        title: '【限时福利】邀新免费抽ipad，仅此一次！速来',
        desc: '更有免单券等你抢>>>【画啦啦小灯塔】',
        shareUrl: host + '/courseList',
      })
    },
    changeTab(index, type) {
      if (this.tabIndex === index) return
      this.tabIndex = index
      this._getOrderInfo(type)
    },
    // all全部查询 lock未解锁查询 unlock解锁查询 refund退款查询
    _getOrderInfo(type) {
      this.$toast({
        type: 'loading',
        forbidClick: true,
        duration: 0
      })
      getOrderInfo(type).then(res => {
        this.$toast.clear()
        if (res.resultCode && res.resultCode.code === 0) {
          this.orderInfo = res.value || []
        }
      }).catch(() => {
        this.$toast.clear()
      })
    },
  }
}
</script>
<style lang='less' scoped>
  .my-order {
    height: 100vh;
    background: #F9FAFC; 

    .tabs {
      justify-content: space-around;
      margin-bottom: 32px;
      padding-top: 16px;
      width: 100%;
      height: 80px;
      background: #fff;

      &-item {
        position: relative;
        font-size: 32px;
        line-height: 32px;
        color: #555B66;

        &.active {
          font-weight: bold;
          color: #FF652F;

          &::after {
            display: block;
            content: '';
            position: absolute;
            bottom: 14px;
            left: 50%;
            transform: translateX(-50%);
            width: 36px;
            height: 6px;
            background: #FF652F;
            border-radius: 200px;
          }
        }
      }
    }

    .no-course {
      padding: 280px 0 32px;
      width: 100%;

      .box {
        margin-bottom: 24px;
        width: 208px;
        height: 140px;
      }

      .tips {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        color: #272D38;
      }
    }
  }
</style>
