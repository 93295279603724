<template>
  <div class="subscribe">
    <div class="tips1 flex-start"><img src="./images/bingo.png" alt="" class="bingo">解锁成功</div>
    <div class="course-name d-flex"><div class="line"></div><div class="name">{{courseName}}</div></div>
    <div class="tips2 flex-start"><div class="cricle box-border"></div>长按关注公众号，开始看课</div>
    <div class="qrcode flex-center flex-column">
      <div class="qrcode-box flex-center box-border"><img src="./images/qrcode.png" alt="" class="real-qrcode"></div>
      <div class="qrcode-tips">长按识别二维码，关注公众号</div>
    </div>
    <div class="tips3 flex-center">微信公众号福利</div>
    <div class="fuli flex-center">
      <div class="fuli-item flex-center flex-column" v-for="fuli in fuliList" :key="fuli.id"><img :src="fuli.imgUrl" alt="">{{fuli.title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      courseName: this.$route.query.courseName || '',
      fuliList: [
        {id: 1, title: '在线学习', imgUrl: require('./images/icon1.png')},
        {id: 2, title: '爆款课程', imgUrl: require('./images/icon2.png')},
        {id: 3, title: '惊喜福利', imgUrl: require('./images/icon3.png')},
      ]
    }
  },
}
</script>
<style lang='less' scoped>
  .subscribe {
    padding-top: 48px;

    .tips1 {
      padding-left: 40px;
      font-size: 32px;
      font-weight: bold;
      line-height: 1;

      .bingo {
        margin-right: 32px;
        width: 40px;
        height: 40px;
      }
    }

    .course-name {
      margin: 12px 0;
      padding-left: 60px;

      .line {
        margin-right: 50px;
        width: 2px;
        height: 104px;
        background: #EDEEF2;
      }

      .name {
        padding-top: 16px;
        font-size: 28px;
        line-height: 28px;
        color: #9BA1AD;
      }
    }

    .tips2 {
      margin-bottom: 44px;
      padding-left: 40px;
      font-size: 32px;
      font-weight: bold;
      line-height: 32px;

      .cricle {
        margin-right: 32px;
        width: 40px;
        height: 40px;
        border: 2px solid #EDEEF2;
        border-radius: 50%;
      }
    }

    .qrcode {
      margin: 0 auto;
      width: 670px;
      height: 592px;
      background: #FFFFFF;
      box-shadow: 0 -6px 40px -4px rgba(77, 61, 153, 0.08);
      border-radius: 32px;

      .qrcode-box {
        margin-bottom: 32px;
        width: 400px;
        height: 400px;
        border-radius: 24px;
        border: 4px solid #FF652F;

        .real-qrcode {
          width: 376px;
          height: 376px;
          outline: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
      }

      .qrcode-tips {
        font-size: 32px;
        font-weight: bold;
        line-height: 32px;
        color: #FF652F;
      }
    }

    .tips3 {
      margin-bottom: 32px;
      font-size: 28px;
      line-height: 28px;
      color: #555B66;

      &::before,
      &::after {
        content: '';
        margin: 0 32px;
        display: block;
        width: 120px;
        height: 2px;
        border-radius: 54px;
        background: #EFEFF1;
      }
    }

    .fuli-item {
      font-size: 24px;
      line-height: 24px;
      color: #555B66;

      img {
        margin-bottom: 16px;
        width: 88px;
        height: 88px;
      }

      &:nth-child(2) {
        margin: 0 112px;
      }
    }
  }
</style>
