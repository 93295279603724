<template>
  <div class="mine d-flex flex-column">
    <div class="courselist flex-1">
      <div class="user-info flex-start box-border">
        <img :src="userInfo.headUrl || defaultHeaderUrl" alt="" class="header flex-shrink" />
        <div class="nickname">{{ userInfo.nickName || '豆丁宝宝' }}</div>
      </div>
      <div class="phone flex-start"><img src="./images/icon_phone.png" alt="" class="icon">绑定手机<div class="tel">{{ userInfo.phone }}</div></div>
      <div class="list flex-start" @click="() => {$router.push('/platform/myOrder')}"><img src="./images/icon_list.png" alt="" class="icon">我的订单<img src="./images/icon_arrow.png" alt="" class="arrow"></div>
      <div class="question flex-start" @click="() => {$router.push('/platform/CommonProblem')}"><img src="./images/icon_question.png" alt="" class="icon">常见问题<img src="./images/icon_arrow.png" alt="" class="arrow"></div>
    </div>
    <d-tabbar></d-tabbar>
  </div>
</template>

<script>
import DTabbar from '@components/DTabbar'
import WXShareMix from '@mixins/wx_share_mix.js'
import {
  getGubiUserInfo,
} from '@apis/feitian_api.js'
export default {
  mixins: [WXShareMix],
  components: {
    DTabbar
  },
  data () {
    return {
      userInfo: {},
      defaultHeaderUrl: require('@assets/imgs/logo/gubi.png')
    }
  },
  mounted () {
    getGubiUserInfo().then(res => {
      this.userInfo = res.value || {}
    })
    this.forbidShare()
  },
  methods: {

  }
}
</script>
<style lang='less' scoped>
  .mine {
    width: 100%;
    height: 100vh;
    overflow: auto;

    .user-info {
      margin-bottom: 16px;
      padding-left: 40px;
      width: 100%;
      height: 176px;
      border-bottom: 16px solid #F9FAFC;

      .header {
        margin-right: 28px;
        width: 96px;
        height: 96px;
        border-radius: 50%;
      }

      .nickname {
        font-size: 32px;
        font-weight: bold;
        line-height: 1;
      }
    }

    .phone,
    .list,
    .question {
      padding-left: 40px;
      margin-bottom: 48px;
      position: relative;
      font-size: 28px;
      color: #272D38;

      .icon {
        margin-right: 16px;
        width: 48px;
        height: 48px;
      }

      .tel {
        position: absolute;
        right: 40px;
        color: #555B66;
      }

      .arrow {
        position: absolute;
        right: 40px;
        width: 22px;
        height: 24px;
      }
    }
  }
</style>
