<template>
    <div class="pg-bind-address">
        <p class="tips-title">
            <img class="icon" src="./images/success-icon.png" alt="">
            <span>订单支付成功</span>
        </p>
        <p class="tips-sub">订单包含实物教具，请填写收货地址以确保宝贝上课效果</p>
        <div class="form">
            <template v-for="item in formRender">
                <template v-if="item.type==='address'">
                    <ft-address
                        :ref="item.prop"
                        :key="item.prop"
                        :info="item"
                        @on-value="({value}) => item.value = value">
                    </ft-address>
                </template>
                <template v-else-if="item.type==='phone'">
                    <ft-phone
                        :ref="item.prop"
                        :key="item.prop"
                        :info="item"
                        @on-value="({value}) => item.value = value">
                    </ft-phone>
                </template>
                <template v-else>
                    <div class="form-item"
                        :key="item.prop">
                        <div class="label">{{item.name}}</div>
                        <div class="value">
                            <component
                                :is="'ft-'+item.type"
                                :info="item"
                                :placeholder="item.placeholder"
                                :options="item.options"
                                @on-value="({value}) => item.value = value">
                            </component>
                        </div>
                    </div>
                </template>
            </template>
        </div>
        <div class="foot">
            <button class="btn" :class="{loading}" @click="submit">
                <van-loading v-if="loading"></van-loading>
                <template v-else>提交</template>
            </button>
        </div>
    </div>
</template>

<script>
import ftSelect from './components/select.vue'
import ftInput from './components/input.vue'
import ftPhone from './components/phone.vue'
import ftAddress from './components/address.vue'
import ftTextarea from './components/textarea.vue'
import ftDate from './components/date.vue'
import { Loading } from 'vant'
import {isWeixin} from '@utils/platform.js'
import { getCourseOrder, refundCourse } from '@apis/feitian_api.js'

export default {
    components: {
        ftSelect,
        ftInput,
        ftPhone,
        ftAddress,
        ftTextarea,
        ftDate,
        'van-loading': Loading
    },
    data() {
        return {
            formRender: [],
            info: {},
            loading: false,
            baseParams: {},
            orderInfo: {}, // 订单信息
        }
    },
    computed: {
        // 已购标识/已购状态码
        purchasedCode() {
            let { purchasedCode } = this.info
            if (!purchasedCode) purchasedCode = [-3000]
            if (purchasedCode && typeof purchasedCode === 'string') {
                purchasedCode = purchasedCode.split(',').map(num => Number(num))
            }
            return purchasedCode
        }
    },
    created() {
        console.info('>>>>> %ccreated', 'color:#db3630')
    },
    mounted() {
        console.info('>>>>> %cmounted', 'color:#7559ff')
    },
    methods: {
        init(next) {
            next()
            const {actId} = this.$route.params
            const { courseId } = this.$route.query
            Promise.all([
                this.$http.get(`/apsara/apis/h5/orders/act-param/${actId}`),
                ...(courseId ? [getCourseOrder({courseId})] : [])
            ]).then(([res, order]) => {
                let {bindAddress='{}', is1AddN} = res.value.keyValue
                bindAddress = JSON.parse(bindAddress)
                if (is1AddN) {
                    if (!courseId) return this.$toast('没有课程id，页面显示失败')
                    bindAddress = bindAddress[courseId]
                }
                this.formRender = bindAddress.form.map(obj => ({value:'', ...obj}))
                delete bindAddress.form
                this.info = bindAddress
                console.info(this.formRender)
                this.baseParams = res.value.baseParams || {}
                if (order !== undefined) this.orderInfo = order.value
                next()
            })
        },
        // 提交数据
        submit() {
            // 表单参数
            let form = {}
            for (const item of this.formRender) {
                const value = item.value
                if (item.required) {
                    if (['address', 'phone'].indexOf(item.type) > -1) {
                        if (!this.$refs[item.prop][0].validator()) return
                    } else {
                        if (value === '') return this.$toast(item.placeholder)
                    }
                }
                form[item.prop] = value
                // 如果是手机号码，需要填写验证码，则获取验证码
                if (item.type === 'phone' && item.sms) {
                    form[item.smsKey || 'captcha'] = this.$refs[item.prop][0].captcha
                }
                // 如果是手机号码，需要验证规则
                if (item.type === 'phone') {
                    if (!/^[1][0-9]{10}$/.test(value)) return this.$toast('请输入正确的手机号码')
                }
            }

            const {query='', submitURL=''} = this.info
            let { params={}, headers } = this.info
            // 链接参数
            if (query) {
                for (const k of query.split(',')) {
                    form[k.trim()] = (this.$route.query[k] || '').trim()
                }
            }
            // 固定参数
            if (params && typeof params === 'string') {
                let obj = {}
                params.split('&').forEach(str => {
                    const [k, v] = str.trim().split('=')
                    obj[k] = v.trim()
                })
                params = obj
            }
            if (params) form = Object.assign(form, params)

            if (!headers) headers = {}
            // 请求头部
            try {
                if (headers && typeof headers === 'string') headers = JSON.parse(headers)
            } catch (err) {
                console.error('请求头部只接收对象字符串')
            }

            this.loading = true
            this.$http.post(submitURL, form, {keepError:true, headers}).then(res => {
                const {resultCode={}} = res
                if (res.code !== undefined) resultCode.code = res.code

                if (resultCode.code !== 0) {
                    if (this.purchasedCode.indexOf(resultCode.code) > -1) {
                        this.fail(resultCode.code)
                    } else {
                        this.$toast(resultCode.message || res.message || res.msg || ('接口状态码' + resultCode.code))
                    }
                } else {
                    this.success()
                }
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        // 入库成功
        success() {
            const {succURL, wxSuccURL} = this.info
            this.$toast('提交成功，即将跳转页面')
            setTimeout(() => {
                const defaultURL = process.env.VUE_APP_DENGTA + '/wx/groupBuy/courseList.html'
                if (isWeixin) {
                    window.location.href = wxSuccURL ? this.formatUrl(wxSuccURL) : defaultURL
                } else {
                    window.location.href = succURL ? this.formatUrl(succURL) : defaultURL
                }
            }, 2000)
        },
        // 入库失败
        fail(code) {
            // 已购买需要退费
            if (this.purchasedCode.indexOf(code) > -1) {
                // refundType 退费类型，0=原路退回，1=退奖学金
                this.baseParams.refundType === 1 ? this.refundAward() : this.refundPrice()
            }
        },
        // 替换url上的{xxx}字符串
        formatUrl(url) {
            const arr = url.match(/\{(.+?)\}/g) || []
            for (const k of arr) {
                url = url.replace(k, this.$route.query[k.substr(1, k.length - 2)] || '')
            }
            return url
        },
        // 原路退回
        refundPrice() {
            this.$popup({
                props: {
                    courseId: this.$route.query.courseId
                },
                componentURL: 'pages/common/bindAddress/components/refundPrice/index.vue',
                on: ({code}, {remove}) => {
                    remove()
                    if (code !== 'cancel') return
                    // 退费
                    this.$http_dengta.post('/dengta/hookcourse/lead/refundByCourseId.json', {
                        courseId: this.$route.query.courseId
                    }).then(() => {
                        // 退费成功，倒计时，跳转到我的课程页面
                        this.$popup({
                            componentURL: 'pages/common/bindAddress/components/refundPrice/success.vue',
                        })
                    })
                }
            })
        },
        // 退奖学金
        refundAward() {
            const { courseId, channel } = this.$route.query
            this.$popup({
                props: {
                    courseId,
                },
                componentURL: 'pages/common/bindAddress/components/refundAward/index.vue',
                on: ({code}, {remove}) => {
                    remove()
                    if (code !== 'cancel') return
                    refundCourse({
                        courseId,
                        orderId: this.orderInfo.orderId
                    }).then(() => {
                        this.$popup({
                            props: {
                                channel
                            },
                            componentURL: 'pages/common/bindAddress/components/refundAward/success.vue',
                        })
                    })
                }
            })
        },
    }
}
</script>

<style lang="less">
.pg-bind-address {
    .tips-title {
        display: flex;
        line-height: 1;
        font-weight: 600;
        font-size: 36px;
        text-align: center;
        margin-top: 64px;
        justify-content: center;
        align-items: center;
        .icon {
            height: 48px;
            margin-right: 16px;
        }
    }
    .tips-sub {
        color: #8f959e;
        font-size: 24px;
        text-align: center;
        margin-top: 32px;
    }
    .form {
        padding: 0 40px;
        margin-top: 64px;
    }
    .form-item {
        display: flex;
        &+.form-item {
            margin-top: 24px;
        }
        .label {
            width: 116px;
            font-weight: 500;
            padding: 26px 0;
        }
        .value {
            flex: 1;
            height: 96px;
            background: #f5f6f7;
            padding: 0 32px;
            margin-left: 24px;
            border-radius: 48px;
        }
        .textarea {
            height: 176px;
        }
    }
    .foot {
        padding: 0 40px;
        margin-top: 64px;
        .btn {
            width: 100%;
            height: 96px;
            background: #7559ff;
            color: #fff;
            font-size: 36px;
            font-weight: 500;
            border: 0;
            border-radius: 48px;
            opacity: 1;
        }
        .btn.loading {
            opacity: .3;
        }
    }
}
</style>