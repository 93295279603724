<template>
  <div class="course box-border d-flex flex-column" :class="{'pb120': lockList && lockList.length > 0}">
    <courseVideo
      :videoOptions="videoOptions"
      :isShowVideo="isShowVideo"
      :isShowVideoCover="isShowVideoCover"
      @playVideo="playVideo"
      @videoFirstPlay="onVideoFirstPlay"
      @timeupdate="onVideoTimeUpdate"
      @ready="onVideoReady"
      @ended="onVideoEnd"
      @pause="onVideoPause"
      @fullscreenchange="onVideoFullScreenChange"
    ></courseVideo>
    <template v-if="courseList.length > 10">
    <div class="tabs d-flex flex-wrap" :class="{'show-more': showMoreTab}">
      <div class="tabs-item box-border flex-center flex-shrink" :class="{active: index === tabIndex}" v-for="(item, index) in tabList" :key="index" @click="changeTab(index)">
        第{{index * tabLen + 1}}<template v-if="item.length > 1">～第{{index * tabLen + item.length}}</template>节
      </div>
    </div>
    <div class="up-down flex-center" :class="{'show-more': showMoreTab}" v-if="tabList.length > 4" @click="showMore"><img src="./images/arrow.png" alt="">{{showMoreTab ? '收起' : '下拉选集'}}</div>
    </template>
    <div class="course-setion flex-1">
      <courseSection :courseList="courseList" :curSectionId="curSectionId" @changeCurSection="changeCurSection" ref="coursesection"></courseSection>
    </div>
    <div class="invite-btn flex-center" v-if="lockList && lockList.length > 0 && inviteInfo && inviteInfo.fissionFinishCount" @click="showPopup = true">还需邀请{{inviteInfo.fissionFinishCount - inviteInfo.inviteCount}}人解锁余下课程</div>
    <sharePoster
      :showPopup="showPopup"
      :posterBg="posterBg"
      :headUrl="posterInfo.headUrl"
      :qrcodeUrl="qrcodeUrl"
      :inviteInfo="inviteInfo"
      ref="shareposter"
    ></sharePoster>
  </div>
</template>

<script>
import index from './index'
export default {
  mixins: [index],
  data () {
    return {
    }
  },
  mounted () {
    document.body.style = "overflow: hidden"
  },
  destroyed () {
    document.body.style = "overflow: auto"
  },
  methods: {
  }
}
</script>
<style lang='less' scoped>
  .course {
    height: 100vh;

    .tabs {
      margin-top: 24px;
      padding-left: 40px;
      height: 56px;
      overflow: hidden;

      &.show-more {
        height: auto;
      }

      &-item {
        margin: 0 12px 24px 0;
        width: 162px;
        height: 56px;
        background: #F7F8FA;
        border-radius: 200px;
        font-size: 22px;
        line-height: 1;

        &.active {
          border: 2px solid #FF652F;
          background: #fff;
          color: #FF652F;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }

    .up-down {
      margin-top: 24px;
      font-size: 24px;
      line-height: 24px;
      color: #9BA1AD;

      img {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }

      &.show-more {
        margin-top: 0;
        img {
          transform: rotateX(180deg);
        }
      }
    }

    .course-setion {
      margin-top: 32px;
      overflow: auto;
      
      .section-num {
        padding: 0 0 32px 40px;
        font-size: 28px;
        line-height: 40px;
        color: #555B66;

        &2 {
          padding-top: 48px;
        }
      }
    }

    .invite-btn {
      position: fixed;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      height: 88px;
      width: 670px;
      border-radius: 120px;
      background: #FF652F;
      font-size: 32px;
      line-height: 1;
      color: #FFFFFF;
    }
  }

  .pb120 {
    padding-bottom: 120px;
  }
</style>
