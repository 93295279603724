<template>
    <div class="ft-refund-succ">
        <p class="title">您的退款申请已提交成功</p>
        <p class="title-sub">购课费用将在3个工作日内自动退回至原支付渠道，请注意查收</p>
        <p class="tips">即将为您自动跳转至课程页 {{time}}s</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            time: 3
        }
    },
    mounted() {
        this.countdown(this.time)
    },
    methods: {
        // 倒计时
        countdown(time) {
            this.time = time
            if (time === 0) {
                window.location.href = process.env.VUE_APP_DENGTA + '/wx/groupBuy/courseList.html'
            } else {
                setTimeout(() => this.countdown(this.time - 1), 1000)
            }
        }
    }
}
</script>

<style lang="less" scoped>
.ft-refund-succ {
    padding: 40px;
    .title {
        line-height: 1;
        font-size: 36px;
        font-weight: 600;
        text-align: center;
    }
    .title-sub {
        line-height: 42px;
        text-align: center;
        margin-top: 40px;
    }
    .tips {
        line-height: 1;
        color: #9ba1ad;
        text-align: center;
        margin-top: 40px;
    }
}
</style>