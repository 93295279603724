<template> 
  <div class="course-section">
    <div class="course-item box-border flex-start" :class="{'playing': !course.lockStatus && idx == curSectionId}" v-for="(course, idx) in courseList" :key="course.courseId" @click="$emit('changeCurSection', course, idx, course.lockStatus)" ref="course">
      <div class="course-bg flex-shrink">
        <img v-lazy="course.coverPic" :key="course.coverPic" alt="">
        <div class="course-mask flex-center" v-if="course.lockStatus">
          <img src="./images/icon_lock.png" alt="" class="icon-lock">
        </div>
      </div>
      <div class="course-info flex-1 d-flex flex-column">
        <div class="course-title two-line-dot">{{idx + 1}}、{{course.courseName}}</div>
        <div class="duration flex-start"><img src="./images/icon_duration.png" alt="" class="icon-duration">{{course.videoLength | secondToDate}}</div>
        <img src="./images/icon_bingo.png" alt="" class="icon-bingo" v-if="!course.lockStatus && course.finWatch && idx != curSectionId">
        <img src="./images/icon_playing.png" alt="" class="icon-playing" v-if="!course.lockStatus && idx == curSectionId && $parent.isPlaying">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    courseList: {
      type: Array,
      default: () => {
        return []
      }
    },
    curSectionId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isFinish: false,
      isPlaying: true,
    }
  },
  mounted () {

  },
  filters: {
    secondToDate: (msd) => {
      var time = msd
      if (null != time && '' != time) {
        if (time > 60 && time < 60 * 60) {
          time = parseInt(time / 60.0) + ':' + parseInt((parseFloat(time / 60.0) -
            parseInt(time / 60.0)) * 60);
        } else if (time >= 60 * 60 && time < 60 * 60 * 24) {
          time = parseInt(time / 3600.0) + ':' + parseInt((parseFloat(time / 3600.0) -
              parseInt(time / 3600.0)) * 60) + ':' +
            parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
              parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60) ;
        } else if (time >= 60 * 60 * 24) {
          time = parseInt(time / 3600.0 / 24) + ':' + parseInt((parseFloat(time / 3600.0 / 24) -
              parseInt(time / 3600.0 / 24)) * 24) + ':' + parseInt((parseFloat(time / 3600.0) -
              parseInt(time / 3600.0)) * 60) + ':' +
            parseInt((parseFloat((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60) -
              parseInt((parseFloat(time / 3600.0) - parseInt(time / 3600.0)) * 60)) * 60);
        } else {
          time = '00:' + parseInt(time);
        }
      }
      return time;
    },
  },
}
</script>
<style lang='less' scoped>
  .course-item {
    margin: 0 auto;
    padding: 16px;
    width: 670px;
    height: 160px;
    border-radius: 16px;

    &.playing {
      background: #FFF7F1;
    }

    .course-bg {
      position: relative;
      margin-right: 16px;
      height: 128px;
      width: 224px;
      border-radius: 16px;
      overflow: hidden;

      img {
        width: 100%;
        vertical-align: top;
      }

      .course-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);

        .icon-lock {
          width: 48px;
          height: 48px;
        }
      } 
    }

    .course-info {
      justify-content: space-between;
      position: relative;
      height: 100%;
      overflow: hidden;

      .course-title {
        font-size: 28px;
        font-weight: bold;
        line-height: 40px;
        color: #272D38;
      }

      .duration {
        font-size: 20px;
        line-height: 1;
        color: #9BA1AD;

        .icon-duration {
          margin-right: 8px;
          width: 24px;
          height: 24px;
        }
      }

      .icon-bingo,
      .icon-playing {
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
        width: 48px;
        height: 48px;
      }
    }
  }
</style>
