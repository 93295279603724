<template>
    <div class="pg-index" @click="handle">Index</div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        handle() {
            this.$router.push('/common/bindAddress/110')
        }
    }
}
</script>

<style lang="less" scoped>
.pg-index {
    padding: 28px 40px;
}
</style>