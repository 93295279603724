<template>
    <van-popup
        :value="value"
        round
        :close-on-click-overlay="false"
        transition-appear>
        <div class="ft-popup">
            <slot></slot>
        </div>
    </van-popup>
</template>

<script>
export default {
    props: {
        value: Boolean
    }
}
</script>

<style lang="less">
.ft-popup {
    width: calc(100vw - 70px);
}
</style>