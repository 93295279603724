<template>
  <div class="course-page d-flex flex-column">
    <div class="courselist flex-1 d-flex flex-column">
      <template v-if="requesFinished">
        <ul class="tabs flex-start">
          <li class="tabs-item" v-for="(tab, index) in tabList" :key="index" :class="{active: tabIndex === index}" @click="changeTab(index)">{{tab}}</li>
        </ul>
        <list
          v-if="courseList && courseList.length > 0"
          :courseList="courseList"
          :inviteCountList="inviteCountList"
        ></list>
        <div class="no-course flex-center flex-column" v-else>
          <img src="./images/box.png" alt="" class="box">
          <div class="tips1">您还没有相关的课程</div>
          <div class="tips2">可以在下方看看有些适合的</div>
        </div>
        <div class="recommend" v-if="(gubiExperienceCoursesInfo && gubiExperienceCoursesInfo.courses && gubiExperienceCoursesInfo.courses.length > 0) || (expansioCoursesInfo && expansioCoursesInfo.channels && expansioCoursesInfo.channels.length > 0)">
          <div class="tips flex-center">您可能感兴趣的课程</div>
          <recommend
            v-if="gubiExperienceCoursesInfo && gubiExperienceCoursesInfo.courses && gubiExperienceCoursesInfo.courses.length > 0"
            :list="gubiExperienceCoursesInfo"
            direction="horizon"
          />
          <recommend
            v-if="expansioCoursesInfo && expansioCoursesInfo.channels && expansioCoursesInfo.channels.length > 0"
            :list="expansioCoursesInfo"
            direction="vertical"
          />
        </div>
      </template>
    </div>
    <div class="bottom-tips flex-center">列表仅展示咕比拓展课哦~其他课程请移步咕比APP观看</div>
    <d-tabbar></d-tabbar>
  </div>
</template>

<script>
  import DTabbar from '@components/DTabbar'
  import list from './components/list.vue'
  import recommend from './components/recommend.vue'
  import WXShareMix from '@mixins/wx_share_mix.js'
  import {
    getGubiUserCourseList,
    getInviteCount,
    getGubiExperienceCourses,
    getExpansioCourses,
    getRecentWatchCourse,
  } from '@apis/feitian_api.js'
  import {
    courseConfig,
    courseConfigTest
  } from '@config/course_config.js'
  export default {
    mixins: [WXShareMix],
    components: {
      DTabbar,
      list,
      recommend,
    },
    data() {
      return {
        courseList: [],
        wxAppId: 'gh_0a3dbde56d7b',
        wxBtnStyle: '',
        wxBtnText: '去挑选课程',
        inviteCountList: [],
        requesFinished: false,
        tabList: ['我的课程'],
        tabIndex: 0,
        gubiExperienceCoursesInfo: {}, // 估比体验课列表
        expansioCoursesInfo: {}, // 拓展课列表
      }
    },
    computed: {
      courseConfig() {
        return process.env.NODE_ENV === 'production' ? courseConfig : courseConfigTest
      }
    },
    mounted() {
      this.initData()
    },
    methods: {
      initData () {
        this.initShare()
        this.initWxBtn()
        this._getExpansioCourses()
        this._getGubiExperienceCourses()
        this._getGubiUserCourseList()
        this.forbidShare()
      },
      _getGubiUserCourseList() {
        getGubiUserCourseList().then(res => {
          if (res.resultCode && res.resultCode.code === 0) {
            const value = res.value || []
            this.courseList = value
            if (value.length > 0) {
              // if (this.tabList.length < 2) {
              //   this.tabList.push('最近学习')
              // }
              this._getInviteCount()
            } else {
              this.requesFinished = true
            }
          } else {
            this.requesFinished = true
          }
        }).catch(() => {
          this.requesFinished = true
        })
      },
      _getInviteCount() {
        let channelList = []
        this.courseList.map(item => {
          if (this.courseConfig[item.recordId || item.courseId]) {
            channelList.push(this.courseConfig[item.recordId || item.courseId].gubiChannel)
          }
        })
        getInviteCount({channelList: channelList.toString()}).then(res => {
          this.requesFinished = true
          if (res.resultCode && res.resultCode.code === 0) {
            this.inviteCountList = res.value || []
          }
        }).catch(() => {
          this.requesFinished = true
        })
      },
      // 咕比体验课推荐模块
      _getGubiExperienceCourses() {
        getGubiExperienceCourses().then(res => {
          if (res.resultCode && res.resultCode.code === 0) {
            this.gubiExperienceCoursesInfo = res.value || {}
          }
        })
      },
      // 拓展课推荐模块
      _getExpansioCourses() {
        getExpansioCourses().then(res => {
          if (res.resultCode && res.resultCode.code === 0) {
            this.expansioCoursesInfo = res.value || {}
          }
        })
      },
      // 用户最近观看
      _getRecentWatchCourse() {
        getRecentWatchCourse().then(res => {
          if (res.resultCode && res.resultCode.code === 0) {
            const list = res.value.list || []
            this.courseList = list
            this._getInviteCount()
          }
        })
      },
      changeTab(index) {
        if (this.tabIndex === index) return
        this.tabIndex = index
        this.requesFinished = false
        if (index === 0) {
          this._getGubiUserCourseList()
        } else {
          this._getRecentWatchCourse()
        }
      },
      initWxBtn() {
        const winWidth = document.body.clientWidth
        this.wxBtnStyle = `
          .weapp-btn {
            display: block; margin: 0 auto; width: ${(winWidth * 288) / 750}px;background-color: #FF652F;color: #fff;font-size: ${(winWidth * 32) / 750}px;line-height: ${(winWidth * 76) / 750}px;border-radius: ${(winWidth * 48) / 750}px;border:none;text-align:center;
          }`
      },
      initShare () {
        let host = window.location.protocol + '//' + window.location.host
        this.initWXShare({
          imgUrl: 'http://test.static2.61info.cn/service/imgs/groupPurchase/act/act20210618/share/img4.png?time=123456',
          title: '【限时福利】邀新免费抽ipad，仅此一次！速来',
          desc: '更有免单券等你抢>>>【画啦啦小灯塔】',
          shareUrl: host + '/courseList',
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .course-page {
    width: 100%;
    height: 100vh;

    .courselist {
      position: relative;
      overflow: auto;
    }

    .tabs {
      margin: 32px 0 56px;
      padding-left: 40px;

      &-item {
        margin-right: 48px;
        position: relative;
        font-size: 32px;
        line-height: 32px;
        color: #555B66;

        &.active {
          font-size: 36px;
          font-weight: bold;
          line-height: 36px;
          color: #1F2329;

          &::after {
            position: absolute;
            bottom: -24px;
            left: 50%;
            transform: translateX(-50%);
            content: '';
            display: block;
            width: 48px;
            height: 12px;
            border-radius: 20px;
            background: #FF652F;
          }
        }
      }
    }

    .no-course {
      padding: 48px 0 32px;
      width: 100%;

      .box {
        margin-bottom: 24px;
        width: 208px;
        height: 140px;
      }

      .tips1 {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        color: #272D38;
      }

      .tips2 {
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        color: #9BA1AD;
      }
    }

    .recommend {
      .tips {
        margin: 120px 0 64px;
        font-size: 28px;
        line-height: 28px;
        color: #FF652F;

        &::before,
        &::after {
          margin: 0 16px;
          display: block;
          content: '';
          width: 80px;
          height: 2px;
          background: linear-gradient(270deg, #FF652F 0%, rgba(255, 101, 47, 0) 100%);
          border-radius: 200px;
        }

        &::after {
          transform: rotateY(180deg);
        }
      }
    }

    .bottom-tips {
      width: 100%;
      height: 64px;
      background: #F9FAFC;
      font-size: 24px;
      line-height: 1;
      color: #9BA1AD;
    }
  }
</style>