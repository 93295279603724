import request from '@utils/request.js'
import http from '@utils/http.js'

// 验证后端接口
const verification = function(res, resolve) {
  if (!res.resultCode) res.resultCode = {}
  if (!res.value) res.value = {}
  if (res.resultCode.code !== 0 && res.resultCode.code !== 2) return this.$toast(res.resultCode.detail || res.resultCode.message || '接口请求出错~')
  resolve(res)
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/joinGubiActUsingPUT
 * 参与活动 创建任务 等
 * @param {String} path channel gubi 渠道号
 */
export function joinActivity(channel) {
  return new Promise((resolve) => {
    request({
      url: `/apsara/v2/apis/course_fission/gubi/join/${channel}`,
      data: {},
      headers: {
        'Content-type': 'application/json;'
      },
      method: 'put',
    }).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/getGuBiUserInfoUsingGET
 * 咕比用户信息
 */
export function getGubiUserInfo() {
  return new Promise((resolve) => {
    request.get('/apsara/v2/apis/course_fission/gubi/userInfo').then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/gubiUserCourseUsingGET_1
 * 获取用户咕比课程列表
 */
export function getGubiUserCourseList() {
  return new Promise((resolve) => {
    request.get('/apsara/v2/apis/course_fission/gubi/user_course', {courseId: 437}).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/gubiUserCourseUsingGET
 * 获取用户咕比课程详情
 * @param {String} path gubiCourseId 课程Id
 */
export function getGubiUserCourseInfo(gubiCourseId) {
  return new Promise((resolve) => {
    request.get(`/apsara/v2/apis/course_fission/gubi/user_course/${gubiCourseId}`).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/getPosterUsingGET
 * 获取获取裂变活动海报信息
 * @param {String} path actId 活动Id
 */
export function getPoster(actId) {
  return new Promise((resolve) => {
    request.get(`/apsara/v2/apis/course_fission/poster/${actId}`).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.42.100:8080/apsara/swagger-ui.html#/wx-account-controller/getSignatureUsingGET
 * 获取微信签名
 * @param {String} params url 当前页面url
 */
export function getSignature(params) {
  return new Promise((resolve) => {
    request.get(`/apsara/v2/apis/wx-account/signature`, {data: params}).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.253.112:8089/doc.html#/default/%E7%94%A8%E6%88%B7%E7%99%BB%E5%BD%95/wechatLoginUsingPOST
 * 用户中心微信登录，获取token
 * @param {String} params code 微信授权code
 */
export function platFormXxLogin(code) {
  const params = {
    channel: 'PLATFORM',
    code,
    scope: 'snsapi_userinfo',
    terminal: 'WEB',
    wechatAppid: 'wx4a26743df30a9fe5'
  }
  return new Promise((resolve) => {
    http.center.post('/bizcenter-usercenter/o/login/wx', params, {
      headers: {
        'Content-type': 'application/json;'
      }
    }).then(res => verification(res, resolve))
    // request({
    //   url: '/bizcenter-usercenter/o/login/wx',
    //   data: params,
    //   headers: {
    //     'Content-type': 'application/json;'
    //   },
    //   method: 'post',
    // }).then(res => verification(res, resolve))
  })
}

/**
 * http://gw-ai-dev.61info.cn/pjx-external/doc.html#/default/%E5%BE%AE%E4%BF%A1%E5%B0%8F%E7%A8%8B%E5%BA%8F/getUnlimitedUsingGET
 * 获取小程序吗
 * @param {String} params scene 自定义拼接参数，长度不能超过32位字符
 */
export function getQrcode(params) {
  return new Promise((resolve) => {
    http.gubi.get('/pjx-external/o/wx/mini/v1.0/wxconfig/getUnlimited', {data: params}).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/userCourseWatchUsingPOST
 * 更新用户进度
 * @param {String} params courseId 具体某一节课的id
 * @param {String} params num 视频序号
 * @param {String} params position 当前看到的位置, 单位秒
 * @param {String} params recordId 主题id，即拓展课的课程id
 * @param {String} params videoLength 视频长度
 */
export function updateUserCourse(params) {
  return new Promise((resolve) => {
    request({
      url: '/apsara/v2/apis/course_fission/gubi/user_course/watch',
      data: params,
      headers: {
        'Content-type': 'application/json;'
      },
      method: 'post',
    }).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/getBuyChannelUsingGET
 * 查找用户所购课程的咕比渠道 差找不到会抛出异常信息： 用户该课程未能寻找到咕比渠道号
 * @param {Number} params courseId 咕比课程id
 */
export function getChannel(params) {
  return new Promise((resolve) => {
    request.get(`/apsara/v2/apis/course_fission/gubi/channel`, {data: params}).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/getFrontEndDataUsingGET
 * 前端埋点数据获取，邀请人数、等级
 * @param {Number} params channel 咕比渠道
 */
export function getPointData(params) {
  return new Promise((resolve) => {
    request.get(`/apsara/v2/apis/course_fission/gubi/front_end`, {data: params}).then(res => verification(res, resolve))
  })
}

/**
 * http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/getInviteCountUsingGET
 * 前端待邀请人数批量获取 匹配不到活动为0,匹配到多个活动取0 建议小于0不要展示
 * @param {Number} params channelList 咕比渠道列表
 */
export function getInviteCount(params) {
  return new Promise((resolve) => {
    request.get(`/apsara/v2/apis/course_fission/gubi/channel/inviteCount`, {data: params}).then(res => verification(res, resolve))
  })
}

/** http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/getExperienceCourseRecommendationModulesUsingGET
* 咕比体验课推荐模块
*/
export function getGubiExperienceCourses() {
  return new Promise((resolve) => {
    request.get('/apsara/v2/apis/course_fission/recommendations/experience-courses/gubi').then(res => verification(res, resolve))
  })
 }

/** http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/getExpansionCourseRecommendationModulesUsingGET
* 拓展课推荐模块
*/
export function getExpansioCourses() {
 return new Promise((resolve) => {
   request.get('/apsara/v2/apis/course_fission/recommendations/expansion-courses').then(res => verification(res, resolve))
 })
}

/** http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/ordersUsingGET
  * 订单页查询 all全部查询 lock未解锁查询 unlock解锁查询 refund退款查询
 * @param {String} params type 类型
*/
export function getOrderInfo(type) {
  return new Promise((resolve) => {
    request.get(`/apsara/v2/apis/course_fission/orders/${type}`).then(res => verification(res, resolve))
  })
}

/** http://172.16.32.251:18090/apsara/v2/swagger-ui.html#/course-fission-controller/ordersUsingGET
  * 用户最近观看, 非咕比用户校验码2001
*/
export function getRecentWatchCourse() {
  return new Promise((resolve) => {
    request.get('/apsara/v2/apis/course_fission/watch').then(res => verification(res, resolve))
  })
}

/**
 * 获取跟某门课程同一笔订单的所有课程信息
 * @param {Number} params courseId 课程id
 */
 export function getCourseOrder(params) {
  return new Promise((resolve) => {
    request.get('/apsara/apis/h5/orders/list-course', {data: params}).then(res => verification(res, resolve))
  })
}

/**
 * 根据课程id退款
 * @param {String} params.courseId 课程id
 * @param {String} params.orderId 订单id
 */
export function refundCourse(params) {
  return new Promise((resolve) => {
    request.post('/apsara/apis/h5/orders/refund-award', params).then(res => verification(res, resolve))
  })
}