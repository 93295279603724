<template>
    <div class="ft-input"
        :class="{'-placeholder': !info.value}">
        <input class="input" type="text" :value="info.value" :placeholder="info.placeholder" @input="onInput">
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => ({}),
        }
    },
    methods: {
        onInput(e) {
            this.$emit('on-value', {value:e.target.value})
        }
    }
}
</script>

<style lang="less" scoped>
.ft-input {
    display: flex;
    height: 100%;
    align-items: center;
    .input {
        width: 100%;
        height: 60px;
        background: transparent;
        color: #1f2329;
        padding: 0;
        border: 0;
    }
    .input::placeholder {
        color: #8f959e;
    }
}
</style>