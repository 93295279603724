var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pg-bind-address"},[_vm._m(0),_c('p',{staticClass:"tips-sub"},[_vm._v("订单包含实物教具，请填写收货地址以确保宝贝上课效果")]),_c('div',{staticClass:"form"},[_vm._l((_vm.formRender),function(item){return [(item.type==='address')?[_c('ft-address',{key:item.prop,ref:item.prop,refInFor:true,attrs:{"info":item},on:{"on-value":function (ref) {
	var value = ref.value;

	return item.value = value;
}}})]:(item.type==='phone')?[_c('ft-phone',{key:item.prop,ref:item.prop,refInFor:true,attrs:{"info":item},on:{"on-value":function (ref) {
	var value = ref.value;

	return item.value = value;
}}})]:[_c('div',{key:item.prop,staticClass:"form-item"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"value"},[_c('ft-'+item.type,{tag:"component",attrs:{"info":item,"placeholder":item.placeholder,"options":item.options},on:{"on-value":function (ref) {
	var value = ref.value;

	return item.value = value;
}}})],1)])]]})],2),_c('div',{staticClass:"foot"},[_c('button',{staticClass:"btn",class:{loading: _vm.loading},on:{"click":_vm.submit}},[(_vm.loading)?_c('van-loading'):[_vm._v("提交")]],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"tips-title"},[_c('img',{staticClass:"icon",attrs:{"src":require("./images/success-icon.png"),"alt":""}}),_c('span',[_vm._v("订单支付成功")])])}]

export { render, staticRenderFns }