import Vue from 'vue'
import FtPopup from './index.vue'

/**
 * 弹窗展示api
 * @param {Function} params.on // 监听事件
 */
export default (params={}) => {
    return new Promise(() => {
        const Instance = new Vue({
            components: {
                FtPopup,
                'is-component': require('/src/' + params.componentURL).default
            },
            data() {
                return {
                    show: true,
                }
            },
            render(h) {
                return h('ft-popup', {
                    attrs: {
                        value: this.show
                    }
                }, [
                    h('is-component', {
                        props: params.props || {},
                        on: {
                            'on-click': e => {
                                params.on && params.on(e, this)
                            }
                        }
                    })
                ])
            },
            methods: {
                remove() {
                    this.show = false
                    setTimeout(() => this.destroy(), 300)
                },
                destroy() {
                    this.$destroy()
                    if (this.$el) document.body.removeChild(this.$el)
                }
            }
        })

        const component = Instance.$mount()
        document.body.appendChild(component.$el)
    })
}