<template>
  <div class="course">
    <template v-if="$parent.tabIndex === 0">
      <div class="course-item flex-start box-border" v-for="(course, idx) in courseList" :key="course.recordId" @click="toLearnCourse(course.recordId)">
        <div class="course-bg">
          <img :src="course.coverUrl" alt="">
          <div class="tips flex-center" v-if="inviteCountList[idx] > 0">还需邀请：{{inviteCountList[idx]}}人</div>
        </div>
        <div class="course-info d-flex flex-column box-border">
          <div class="course-name">{{course.name}}</div>
          <div class="course-process flex-start">解锁至&nbsp;<span>{{course.unlockNums}}/{{course.total}}</span>&nbsp;集&nbsp;<div class="br"></div>&nbsp;学习进度&nbsp;<span>{{(course.watchNums / course.total * 100).toFixed(0)}}%</span></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-for="(course, idx) in courseList" :key="course.courseId" @click="toLearnCourse(course.courseId)">
        <div class="course-item flex-start box-border" v-if="course.lastWatchTime">
          <div class="course-bg">
            <img :src="course.coverPicture" alt="">
            <div class="tips flex-center" v-if="inviteCountList[idx] > 0">还需邀请：{{inviteCountList[idx]}}人</div>
          </div>
          <div class="course-info d-flex flex-column box-border">
            <div class="course-name">{{course.courseName}}</div>
            <div class="course-process flex-start">解锁至&nbsp;<span>{{course.courseWareGotNum}}/{{course.coursewareNum}}</span>&nbsp;集&nbsp;<div class="br"></div>&nbsp;学习进度&nbsp;<span>{{(course.courseWareFinishNum / course.coursewareNum * 100).toFixed(0)}}%</span></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    courseList: {
      type: Array,
      default: () => {
        return []
      }
    },
    inviteCountList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {}
  },
  mounted () {

  },
  methods: {
    toLearnCourse(recordId) {
      this.$router.push({
        path: '/platform/learnCourse',
        query: {
          courseId: recordId
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
  .course-item {
    padding: 0 40px;
    width: 100%;
    height: 206px;

    .course-bg {
      margin-right: 24px;
      position: relative;
      width: 206px;
      height: 206px;
      border-radius: 24px;
      flex-shrink: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        vertical-align: top;
      }

      .tips {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        background: #FF9671;
        font-size: 24px;
        line-height: 1;
        color: #fff;
      }
    }

    .course-info {
      justify-content: space-between;
      padding: 8px 0;
      width: 100%;
      height: 100%;

      .course-name {
        font-size: 32px;
        font-weight: bold;
        line-height: 1;
        color: #272D38;
      }

      .course-process {
        font-size: 24px;
        line-height: 1;
        color: #8F959E;

        .br {
          height: 20px;
          width: 2px;
          background: #8F959E;
        }

        span {
          color: #f3b248;
        }
      }
    }

    & + .course-item {
      margin-top: 48px;
    }
  }
</style>
