<template>
    <div class="pg-test">
        <button @click="jump">跳转到小灯塔主站</button>
        <button @click="readCookie">读取cookie</button>
        <p>{{cookie}}</p>
        <button @click="clearCookie">清除cookie</button>
        <button @click="useApi(2)">主站接口</button>
        <button @click="useApi(1)">飞天接口</button>
        <button @click="useApi(3)">user api</button>
        <p>{{json}}</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cookie: '',
            json: {}
        }
    },
    mounted() {
    },
    methods: {
        getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(';');
            for(var i=0; i<ca.length; i++) 
            {
                var c = ca[i].trim();
                if (c.indexOf(name)==0) return c.substring(name.length,c.length);
            }
            return "";
        },
        jump() {
            window.location.href = '//wx-1.dengtacourse.com/wx/groupBuy/courseList.html'
        },
        readCookie() {
            this.cookie = this.getCookie('dttk')
        },
        clearCookie() {
            var keys = document.cookie.match(/[^ =;]+(?==)/g)
            if (keys) {
                for (var i = keys.length; i--;) {
                document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() // 清除当前域名下的,例如：m.ratingdog.cn
                document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() // 清除当前域名下的，例如 .m.ratingdog.cn
                document.cookie = keys[i] + '=0;path=/;domain=dengtacourse.com;expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的，例如 .ratingdog.cn
                }
            }
        },
        useApi(t) {
            switch(t) {
                case 1:
                    this.$http.get('/apsara/apis/h5/courses/437/learning/activities/present-courses?channel=0').then(res => this.json = res)
                    break
                case 2:
                    this.$http_dengta.get('/dengta/common/act/10071/getPeriodInfo.json?couponRainType=5').then(res => this.json = res)
                    break
                case 3:
                    this.$http.get('/apsara/apis/h5/orders/user', {keepError:true}).then(res => {
                        this.json = res
                        if (res.resultCode.code === -10001) {
                            window.location.href = res.value.authUrl
                        }
                    })
                    break
            }
        }
    }
}
</script>

<style lang="less" scoped>
.pg-test {
    padding: 40px;
}
</style>