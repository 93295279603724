import Vue from 'vue'
import { Cascader } from 'vant'
import chinaAddress from './chinaAddress.json'

// 数据归类
let address = []
let childObj = {}
for (const item of chinaAddress) {
    if (item.parent) {
        childObj[item.parent] ? childObj[item.parent].push(item) : childObj[item.parent] = [item]
    } else {
        address.push({...item, children: []})
    }
}
for (const item of address) {
    const children = childObj[item.value]
    if (children) {
        for (const child of children) {
            child.children = childObj[child.value] || []
        }
    }
    item.children = children || []
}

/**
 * 选择地区api
 * @param {String} params.value // 复选数据，例填写1002001002(朝阳区)
 */
export default (params={}) => {
    return new Promise((resolve) => {
        const Instance = new Vue({
            components: {
                'van-cascader': Cascader
            },
            data() {
                return {
                    show: true,
                }
            },
            render(h) {
                return h('van-popup', {
                    attrs: {
                        value: this.show,
                        round: true,
                        position: 'bottom',
                        'close-on-click-overlay': false,
                        'transition-appear': true,
                        'safe-area-inset-bottom': true,
                    },
                }, [
                    h('van-cascader', {
                        attrs: {
                            value: params.value || '',
                            title: '请选择你的地区',
                            options: address,
                            'active-color': '#7559ff',
                            'field-names': {
                                text: 'name',
                                value: 'value',
                                children: 'children'
                            }
                        },
                        on: {
                            close: () => {
                                this.remove()
                            },
                            finish: ({selectedOptions}) => {
                                resolve({value:selectedOptions})
                                this.remove()
                            }
                        }
                    })
                ])
            },
            methods: {
                remove() {
                    this.show = false
                    setTimeout(() => this.destroy(), 300)
                },
                destroy() {
                    this.$destroy()
                    if (this.$el) document.body.removeChild(this.$el)
                }
            }
        })

        const component = Instance.$mount()
        document.body.appendChild(component.$el)
    })
}