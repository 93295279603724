<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import VConsole from 'vconsole'

export default {
    created() {
        // 开启控制台
        if (window.location.href.indexOf('vconsole') > -1) new VConsole()
    }
}
</script>

<style lang="less">
body {
    line-height: 1.5715;
    color: #1f2329;
    font-size: 28px;
}
p {
    margin: 0;
}
img {
    vertical-align: top;
}
</style>
