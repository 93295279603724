<template>
    <div class="ft-select"
        :class="{'-placeholder': !label}"
        @click="onPicker">
        {{label || info.placeholder}}
    </div>
</template>

<script>
import Datetime from '@components/datetime/index.js'
import dayjs from 'dayjs'

export default {
    props: {
        info: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            label: ''
        }
    },
    computed: {
        format() {
            const { format, dateTYPE } = this.info
            if (format) return format
            switch(dateTYPE) {
                case 'year-month':
                    return 'YYYY/MM'
                case 'date':
                    return 'YYYY/MM/DD'
                case 'datetime':
                    return 'YYYY/MM/DD HH:mm:ss'
            }
            return 'YYYY/MM/DD'
        }
    },
    methods: {
        onPicker() {
            const {value, placeholder, dateTYPE} = this.info
            Datetime({
                value,
                title: placeholder,
                type: dateTYPE,
            }).then((date) => {
                const value = date ? dayjs(date).format(this.format) : ''
                this.label = value
                this.$emit('on-value', {value})
            })
        }
    }
}
</script>

<style lang="less" scoped>
.ft-select {
    display: flex;
    height: 100%;
    align-items: center;
    &.-placeholder {
        color: #8f959e;
    }
}
</style>