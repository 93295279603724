import Vue from 'vue'
import { DatetimePicker } from 'vant'
const dateCN = {year:'年', month:'月', day:'日', hour:'时', minute:'分' }

/**
 * 选择数据api
 * @param {String} params.value // 复选数据
 * @param {String} params.title // 弹窗标题
 * @param {Array} params.columns // 数据 => [{label:'名称', value:'值'}]
 */
export default (params={}) => {
    return new Promise((resolve) => {
        const Instance = new Vue({
            components: {
                'van-datetime-picker': DatetimePicker,
            },
            data() {
                return {
                    show: true,
                }
            },
            render(h) {
                return h('van-popup', {
                    attrs: {
                        value: this.show,
                        round: true,
                        position: 'bottom',
                        'close-on-click-overlay': false,
                        'transition-appear': true,
                        'safe-area-inset-bottom': true,
                    },
                }, [
                    h('van-datetime-picker', {
                        attrs: {
                            value: params.value ? (typeof params.value === 'string' ? new Date(params.value) : params.value) : new Date(),
                            title: params.title || '请选择时间',
                            type: params.type || 'date',
                            'show-toolbar': true,
                            formatter(type, val) {
                                return val + (dateCN[type] || '')
                            }
                        },
                        on: {
                            confirm: e => {
                                resolve(e)
                                this.remove()
                            },
                            cancel: this.remove
                        }
                    })
                ])
            },
            methods: {
                remove() {
                    this.show = false
                    setTimeout(() => this.destroy(), 300)
                },
                destroy() {
                    this.$destroy()
                    if (this.$el) document.body.removeChild(this.$el)
                }
            }
        })

        const component = Instance.$mount()
        document.body.appendChild(component.$el)
    })
}