
import courseVideo from './components/courseVideo/index.vue'
import courseSection from './components/courseSection/index.vue'
import sharePoster from './components/sharePoster/index.vue'
import WXShareMix from '@mixins/wx_share_mix.js'
import {
  courseConfig
} from '@config/course_config.js'
import {
  joinActivity,
  getGubiUserCourseInfo,
  getPoster,
  getQrcode,
  updateUserCourse,
  getChannel,
  getPointData,
} from '@apis/feitian_api.js'
export default {
  mixins: [WXShareMix],
  components: {
    courseVideo,
    courseSection,
    sharePoster,
  },
  computed: {
    courseList() {
      return this.unlockList.concat(this.lockList) || []
    },
    unlockList() {
      return this.courseInfo.unlockList || []
    },
    lockList() {
      return this.courseInfo.lockList || []
    },
    pageData() {
      return courseConfig[this.courseId] || {}
    },
    tabList() {
      let index = 0
      let count = this.tabLen
      let arrTemp = []
      let experts = this.courseList
      for (let i = 0; i < this.courseList.length; i++) {
        index = parseInt(i/count)
        if (arrTemp.length <= index) {
          arrTemp.push([])
        }
        arrTemp[index].push(experts[i])
      }
      return arrTemp
    }
  },
  data() {
    return {
      courseName: this.$route.query.courseName || '',
      courseId: this.$route.query.courseId || 0,
      courseInfo: {},
      videoOptions: {
        videoSource: '',
        posterImg: '',
        type: 'application/x-mpegURL',
      },
      isShowVideo: false,
      isShowVideoCover: true,
      showPopup: false,
      actId: 1,
      qrcodeUrl: '',
      inviteNum: 0,
      isInvite: false,
      curCourseInfo: {},
      curSectionId: 0, // 当前播放章节id
      isChangingSection: false,
      posterInfo: {},
      posterBg: '',
      posterBgList: {
        10082: ['http://static2.61info.com/service/imgs/feitian/poster/10082/1.jpg?v=3', 'http://static2.61info.com/service/imgs/feitian/poster/10082/2.jpg?v=3'],
        10092: ['http://static2.61info.com/service/imgs/feitian/poster/10092/1.jpg?v=3', 'http://static2.61info.com/service/imgs/feitian/poster/10092/2.jpg?v=3'],
        10101: ['http://static2.61info.com/service/imgs/feitian/poster/10101/1.jpg?v=3', 'http://static2.61info.com/service/imgs/feitian/poster/10101/2.jpg?v=3'],
        10103: ['http://static2.61info.com/service/imgs/feitian/poster/10103/1.jpg?v=3', 'http://static2.61info.com/service/imgs/feitian/poster/10103/2.jpg?v=3'],
        10105: ['http://static2.61info.com/service/imgs/feitian/poster/10105/1.jpg?v=3', 'http://static2.61info.com/service/imgs/feitian/poster/10105/2.jpg?v=3']
      },
      updateTimer: null,
      gubiChannel: '',
      inviteInfo: {}, // 邀请人数信息
      isPlaying: false,
      tabIndex: 0,
      tabLen: 10,
      showMoreTab: false,
      recentWatchSection: 1, // 最近观看
    }
  },
  watch: {
    showPopup(value) {
      if (value) {
        this.pgExposeActivitypage()
        if (!this.$refs.shareposter.posterWrapperUrl) {
          this.$toast({
            message: '海报生成中，请稍等',
            forbidClick: true,
            duration: 0
          })
        }
      }
    }
  },
  mounted() {
    this.initData()
  },
  destroy() {
    clearInterval(this.updateTimer)
    this.updateTimer = null
  },
  methods: {
    async initData() {
      this.forbidShare()
      this.initPoster()
      this._getGubiUserCourseInfo()
      await this._getChannel()
      await this._joinActivity(this.gubiChannel)
      this._getPointData()
      await this._getPoster(this.actId)
      this._getQrcode()
    },
    // 获取最近观看课程
    getRecentWatchSection() {
      const recentWatchSection = localStorage.getItem(`course_${this.courseId}`) || 1
      const index = recentWatchSection / 10
      this.recentWatchSection = recentWatchSection
      this.isChangingSection = true
      this.curSectionId = recentWatchSection - 1
      this.updateVideoOptions({
        videoSource: this.courseList[recentWatchSection - 1].video,
        posterImg: this.courseList[recentWatchSection - 1].coverPic,
      })
      this.tabIndex = Math.floor(index)
      this.$nextTick(() => {
        this.$refs.coursesection.$refs.course[recentWatchSection - 1].scrollIntoView({
          behavior: 'smooth'
        })
      })
    },
    initPoster () {
      let index = Math.random()
      index = index < 0.5 ? Math.floor(index) : Math.ceil(index)
      if (!this.posterBgList[parseInt(this.courseId)]) {
        this.posterBg = this.posterBgList[10105][index] || ''
        return
      }
      this.posterBg = this.posterBgList[parseInt(this.courseId)][index] || ''
    },
    // 获取埋点数据
    _getPointData() {
      getPointData({channel: this.gubiChannel}).then(res => {
        if (res.resultCode && res.resultCode.code === 0) {
          this.inviteInfo = res.value || {}
          this.activityPageExpose()
        }
      })
    },
    _getChannel () {
      return getChannel({courseId: this.courseId}).then(res => {
        if (res.resultCode && res.resultCode.code === 0) {
          this.gubiChannel = res.value || ''
        } else {
          this.gubiChannel = this.pageData.gubiChannel || ''
        }
      })
    },
    _joinActivity(gubiChannel) {
      return joinActivity(gubiChannel).then(res => {
        if (res.resultCode && res.resultCode.code === 0) {
          this.actId = res.value
        }
      })
    },
    _getGubiUserCourseInfo () {
      getGubiUserCourseInfo(this.courseId).then(res => {
        if (res.resultCode && res.resultCode.code === 0) {
          this.courseInfo = res.value || {}
          document.title = this.courseInfo.name || ''
          // 初始化第一小节
          const firstCourse = this.courseInfo.unlockList && this.courseInfo.unlockList[0]
          this.curCourseInfo = firstCourse || {}
          this.isChangingSection = true
          this.updateVideoOptions({
            videoSource: firstCourse.video,
            posterImg: firstCourse.coverPic,
          })
          setTimeout(() => {
            this.getRecentWatchSection()
          }, 500);
        }
      })
    },
    // 获取海报信息
    _getPoster(actId){
      return getPoster(actId).then(res => {
        if (res.resultCode && res.resultCode.code === 0) {
          this.posterInfo = res.value || {}
        }
      })
    },
    // 获取小程序吗
    _getQrcode () {
      // c: 凭证；g：估比 渠道号；i：课程id；l：邀请人层级
      const scene = `c=${this.posterInfo.cert}&g=${this.posterInfo.guBiChannel}&i=${this.courseId}&l=${this.inviteInfo.inviteLevel}`
      const params = {
        scene,
        page: process.env.NODE_ENV === 'production' ? 'ftCourse/pages/course/v0/index' : ''
      }
      getQrcode(params).then(res => {
        if (res.resultCode && res.resultCode.code == 0) {
          this.qrcodeUrl =  'data:image/jpeg;base64,' + res.data
          console.log(this.qrcodeUrl)
          if (this.lockList.length < 1) return
          if (!localStorage.getItem(`firsttime_${this.courseId}`) || this.$route.query.showPoster === 'true') {
            localStorage.setItem(`firsttime_${this.courseId}`, 1)
            this.showPopup = true
          }
        }
      })
    },
    changeCurSection (courseInfo, idx, isLock) {
      if (isLock) {
        this.showPopup = true
        return
      }
      if (this.curSectionId === idx) return
      this.curCourseInfo = courseInfo
      this.isChangingSection = true
      this.isShowVideoCover = true
      this.updateVideoOptions({
        videoSource: courseInfo.video,
        posterImg: courseInfo.coverPic,
      })
      this.curSectionId = idx
      clearInterval(this.updateTimer)
      this.updateTimer = null
      this.videoPlayer.pause()
    },
    playVideo() {
      this.isChangingSection = false
      this.videoPlayer.currentTime(this.curCourseInfo.pos)
      this.videoPlayer.play()
    },
    onVideoFirstPlay () {
      this.isShowVideoCover = false
    },
    onVideoTimeUpdate() {},
    onVideoReady($event) {
      this.videoPlayer = $event
      this.addPlayListener()
      this.addSeekedListener()
    },
    onVideoEnd() {
      clearInterval(this.updateTimer)
      this.updateTimer = null
      this.isPlaying = false
      this.curCourseInfo.finWatch = true
      if (this.curSectionId + 1 === this.unlockList.length && this.lockList.length > 0) {
        this.showPopup = true
      }
    },
    // 视频暂停
    onVideoPause() {
      clearInterval(this.updateTimer)
      this.updateTimer = null
      this.isPlaying = false
    },
    // 视频退出全屏
    onVideoFullScreenChange() {},
    addPlayListener() {
      this.videoPlayer.on('play', () => {
        localStorage.setItem(`course_${this.courseId}`, this.curSectionId + 1)
        this.isPlaying = true
        this._updateUserCourse()
      })
    },
    addSeekedListener() {
      // 拖拽进度按钮/点击进度条切换进度，完成切换后视频继续播放
      this.videoPlayer.on('seeked', () => {
        if (this.isChangingSection) {
          clearInterval(this.updateTimer)
          this.updateTimer = null
          return
        }
        this.videoPlayer.play()
      })
    },
    updateVideoOptions({videoSource = '', posterImg = ''} = {}) {
      this.isShowVideo = false
      const options = {
        ...this.videoOptions,
        videoSource,
        posterImg
      }
      if (videoSource) {
        options.type = videoSource.endsWith('.m3u8') ? 'application/x-mpegURL' : 'video/mp4'
      }
      this.videoOptions = options
      setTimeout(() => {
        this.isShowVideo = true
      })
    },
    // 更新课程进度
    _updateUserCourse () {
      if (this.updateTimer) return
      this.updateTimer = setInterval(() => {        
        const {courseId, num, videoLength} = this.curCourseInfo || {}
        let data = {
          courseId,
          num,
          position: parseInt(this.videoPlayer.currentTime() || 0),
          recordId: this.courseId,
          videoLength
        }
        updateUserCourse(data).then(() => {
          this.unlockList[this.curSectionId].pos = data.position
        })
      }, 5000);   
    },
    changeTab(index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
      this.$nextTick(() => {
        this.$refs.coursesection.$refs.course[index * this.tabLen].scrollIntoView({
          behavior: 'smooth'
        })
      })
    },
    showMore() {
      this.showMoreTab = !this.showMoreTab
    },
    activityPageExpose() {
      const pageData = courseConfig[this.courseId] || {}
      const data = {
        p_page_id: pageData.pageName,
        p_channel: pageData.channel,
        invite_num: this.inviteInfo.inviteCount || 0,
        is_invite: this.inviteInfo.inviteLevel == 1 ? false : true,
      }
      window.sensors_pvuv('pg_expose_itemdetail', data)
    },
    pgExposeActivitypage() {
      const pageData = courseConfig[this.courseId] || {}
      const data = {
        p_page_id: ' 分享海报',
        p_item_id: this.courseId,
        p_channel: pageData.channel,
        invite_level: this.inviteInfo.inviteLevel || 1,
      }
      window.sensors_pvuv('pg_expose_activity', data)
    },
  },
}
