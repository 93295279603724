<template>
  <div class="d-tabbar flex-around bg-white">
    <div
      @click="navToPage(tabBar)"
      v-for="(tabBar, index) in tabBars"
      :key="index"
      :class="['col-'+tabBars.length, {'active': currentRouteName === tabBar.routeName}]"
      class="bar-item d-flex jc-center">
      <div class="icon-wrap flex-center flex-column">
        <img v-if="currentRouteName === tabBar.routeName" class="icon" :src="tabBar.acitveIcon" alt="">
        <img v-else class="icon" :src="tabBar.icon" alt="">
        <span class="fs-xs" :class="{'active': currentRouteName === tabBar.routeName }" :style="{color: tabBar.fontColor}">{{tabBar.text}}</span>
        <!-- 红点 -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'd-tabbar',
  data() {
    return {
      tabBars: [
        {
          text: '课程',
          icon: require('@assets/imgs/tabbar/course_list.png'),
          acitveIcon: require('@assets/imgs/tabbar/course_list_active.png'),
          routeName: 'CourseList',
          redirect: () => {
            this.$router.push({name: 'CourseList'})
          },
        },
        {
          text: '我的',
          icon: require('@assets/imgs/tabbar/mine.png'),
          acitveIcon: require('@assets/imgs/tabbar/mine_active.png'),
          routeName: 'Mine',
          redirect: () => {
            this.$router.push({name: 'Mine'})
          },
        },
      ],
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    navToPage(tabbar) {
      if (this.currentRouteName === tabbar.routeName) {
        return
      }
      tabbar.redirect()
    },
  }
}
</script>

<style lang="less" scoped>
.d-tabbar {
  height: 98px;
  box-shadow: 0 4px 20px rgba(31, 35, 41, 0.07);
  font-size: 20px;
  line-height: 1;
  .icon-wrap {
    position: relative;
  }
  .icon {
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }
  .fs-xs {
    color: #9BA1AD;
    &.active {
      color: #9BA1AD;
      font-weight: bold;
    }
  }
}
</style>
