<template>
    <div class="ft-address">
        <div class="form-item">
            <div class="label">{{info.name}}</div>
            <div class="value">
                <div class="prefix">+86</div>
                <input class="input" type="text" :value="info.value" :placeholder="info.placeholder" @input="onInput">
            </div>
        </div>
        <div class="form-item"
            v-if="info.sms">
            <div class="label captcha">
                <span>验</span><span>证</span><span>码</span>
            </div>
            <div class="value captcha">
                <input class="input" type="text" :value="captcha" placeholder="短信验证码" @input="onCaptcha">
                <div class="btn-code"
                    :class="{disabled:time!==0}"
                    @click="setCaptcha">
                    {{time === 0 ? '获取验证码' : Time}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            captcha: '', // 验证码
            time: 0,
        }
    },
    mounted() {
        // 如果链接参数有该属性，需要默认填写
        const {query} = this.$route
        const {prop} = this.info
        if (query[prop]) this.onInput({target:{value:query[prop]}})
    },
    computed: {
        Time() {
            return (this.time < 10 ? ('0' + this.time) : this.time) + 's'
        }
    },
    methods: {
        onInput(e) {
            let {value} = e.target
            if (value.length > 11) value = value.substr(0, 11)
            e.target.value = value
            this.$emit('on-value', {value})
        },
        onCaptcha(e) {
            let {value} = e.target
            if (value.length > 6) value = value.substr(0, 6)
            e.target.value = value
            this.captcha = value
        },
        // 发送验证码
        setCaptcha() {
            if (this.time !== 0) return

            const {value, name, placeholder, smsURL} = this.info
            if (!value) {
                return this.$toast(placeholder)
            }
            if (value.length !== 11) {
                return this.$toast('请输入正确的' + name)
            }
            this.$http.post(smsURL, {
                tel: value,
                receiverTel: value,
            }).then(() => {
                this.$toast('验证码发送成功，请注意查收')
                this.countdown(60)
            })
        },
        // 倒计时
        countdown(time) {
            this.time = time
            if (time === 0) return
            setTimeout(() => this.countdown(time - 1), 1000)
        },
        // 校验数据
        validator() {
            const {value, name, placeholder} = this.info
            if (!value) {
                this.$toast(placeholder)
                return false
            }
            if (value.length !== 11 || !/^[1][0-9]{10}$/.test(value)) {
                this.$toast('请输入正确的' + name)
                return false
            }
            if (!this.captcha) {
                this.$toast('请输入短信验证码')
                return false
            }
            return true
        }
    }
}
</script>

<style lang="less" scoped>
.prefix {
    margin-right: 20px;
}
.ft-address {
    .form-item {
        display: flex;
        margin-top: 24px;
        .label {
            width: 116px;
            font-weight: 500;
            padding: 26px 0;
        }
        .label.captcha {
            display: flex;
            width: 112px;
            justify-content: space-between;
            margin-right: 4px;
        }
        .value {
            flex: 1;
            display: flex;
            height: 96px;
            background: #f5f6f7;
            padding: 0 32px;
            margin-left: 24px;
            border-radius: 48px;
            align-items: center;
        }
        .value.captcha .input {
            flex: 1;
        }
        .textarea {
            height: 176px;
        }
    }
    .-placeholder {
        color: #8f959e;
    }
    .input {
        width: 100%;
        height: calc(100% - 52px);
        background: transparent;
        color: #1f2329;
        border: 0;
        padding: 0;
        resize: none;
    }
    .input::placeholder {
        color: #8f959e;
    }
    .btn-code {
        color: #7559ff;
        &.disabled {
            color: #9ba1ad;
        }
    }
}
</style>