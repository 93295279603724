import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Popup, Toast } from 'vant'
import request from '@utils/request.js'
import http from '@utils/http.js'
import popup from '@components/popup/index.js'

import '@assets/css/feitian.less'

Vue.use(Popup)
Vue.use(Toast)
Vue.prototype.$http = request
Vue.prototype.$http_dengta = http.dengta
Vue.prototype.$http_gubi = http.gubi
Vue.prototype.$http_center = http.center
Vue.prototype.$popup = popup

Vue.config.productionTip = false

// 路由进入之前拦截
router.beforeEach((to, from, next) => {
    const meta = to.meta || {}
    // 设置页面标题
    if (meta.title) document.title = meta.title
    // loading
    Toast.loading({message:'loading...', duration: 0})
    next()
})
// 页面进入之前拦截
Vue.mixin({
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.init ? vm.init(() => Toast.clear()) : Toast.clear()
        })
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
