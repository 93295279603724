import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/platform/courseList',
    name: 'CourseList',
    component: () => import('@pages/courseList/index.vue'),
    meta: {
      title: '我的课堂'
    }
  },
  {
    path: '/platform/mine',
    name: 'Mine',
    component: () => import('@pages/mine/index.vue'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/platform/learnCourse',
    name: 'LearnCourse',
    component: () => import('@pages/learnCourse/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/platform/subscribeDetail',
    name: 'SubscribeDetail',
    component: () => import('@pages/subscribeDetail/index.vue'),
    meta: {
      title: '关注公众号'
    }
  },
  {
    path: '/platform/myOrder',
    name: 'MyOrder',
    component: () => import('@pages/myOrder/index.vue'),
    meta: {
      title: '我的订单'
    }
  },
  {
    path: '/platform/commonProblem',
    name: 'CommonProblem',
    component: () => import('@pages/commonProblem/index.vue'),
    meta: {
      title: '常见问题'
    }
  },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
