import axios from 'axios'
import Qs from 'qs'
import { Toast } from 'vant'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: 'http://172.16.42.100:8080',
    timeout: 12000, // 设置请求时长
    headers: {
        'Content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
        // 'Access-Control-Allow-Origin': '*',
    },
})
const whiteHeaders = ['application/json', 'multipart/form-data']
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i=0; i<ca.length; i++) 
  {
    var c = ca[i].trim();
    if (c.indexOf(name)==0) return c.substring(name.length,c.length);
  }
  return "";
}
// 请求拦截
service.interceptors.request.use(
    config => {
        //   平台 Token
        const platFromToken = localStorage.getItem('platFrom_token')
        // 小灯塔 Token
        const XDTToken = getCookie('dttk')
        // 如果是v2接口即平台用户体系，否则用的小灯塔用户体系
        config.headers['Authorization'] = config.url.indexOf('/v2/') > -1 ? platFromToken : (XDTToken || platFromToken)
        config.headers['dttk'] = XDTToken

        let data = config.data || {}
        NProgress.start()
        let needFormat = true
        for (const type of whiteHeaders) {
            if (config.headers['Content-type'].indexOf(type) > -1) {
                needFormat = false
                break
            }
        }
        if (needFormat) {
            config.data = Qs.stringify(config.data || {})
        }
        if (config.method === 'get') {
            let params = config.params || {}
            config.params = Object.assign({}, params, data)
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 返回拦截
service.interceptors.response.use(
    response => {
        NProgress.done()
        const res = response.data
        // 对接口返参进行兼容
        if (!res.resultCode) res.resultCode = {}
        if (res.code !== undefined) res.resultCode.code = res.code
        if (res.resultCode.code === 1) {
            localStorage.removeItem('platFrom_token')
            location.reload()
            return
        }

        if (res.resultCode.code === 0 || response.config.keepError) {
            return res
        } else if (res.resultCode.code === 2) {
            return res
        } else {
            Toast(res.resultCode.message || res.msg || ('接口状态码' + res.resultCode.code))
            return Promise.reject(res)
        }
    },
    error => {
        NProgress.done()
        return Promise.reject(error)
    }
)

export default service