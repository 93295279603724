import wx from 'weixin-js-sdk'
import {
  getSignature,
} from '@apis/feitian_api.js'
export default {
  data() {
    return {
      wxShareData: {
        'appId': 'wx4a26743df30a9fe5',
        'appType': 25,
        'shareUrl': '',
        'title': '',
        'desc': '',
        'imgUrl': '',
        'nonce': 'myProfit',
        'isDebug': false,
        'successFun': function () {
        },
        'cancelFun': function () {
        }
      }
    }
  },
  methods: {
    /**
     * 微信初始化分享
     * @param title
     * @param desc
     * @param shareUrl
     **/
    initWXShare(config) {
      let options = Object.assign(this.wxShareData, config)
      let url = location.href.split('#')[0]

      // const nonceStr = options.nonce
      const shareTitle = options.title || options.shareTitle
      const shareDesc = options.desc || options.shareDesc
      let shareLink = options.shareUrl || url
      const shareImgUrl = options.imgUrl || options.shareImageUrl
      const isDebug = options.isDebug
      // const appId = options.appId
      // const timestamp = new Date().getTime()
      console.log('share>>>', shareLink, shareImgUrl)

      let menuList = options.hideMenuList || []
      let showMenuList = options.showMenuList || []
      const queryData = {
        // appType: options.appType,
        // noncestr: nonceStr,
        // timestamp: timestamp,
        url: this.$store.state.wxSignUrl || url
      }
      if (shareLink.indexOf('http') !== 0) {
        if (shareLink.indexOf('//') === 0) {
          shareLink = location.protocol + shareLink
        } else if (shareLink.indexOf('/') === 0) {
          shareLink = location.protocol + '/' + shareLink
        } else {
          shareLink = location.protocol + '//' + shareLink
        }
      }

      return new Promise((resolve, reject) => {
        getSignature(queryData).then(function (res) {
          const {appId, nonceStr, signature, timestamp} = res.value || {}
          wx.config({
            debug: isDebug,
            appId: appId,
            timestamp: '' + timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: [
              'chooseImage',
              'getLocalImgData',
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'hideMenuItems'
            ],
            openTagList: ['wx-open-launch-weapp']
          })
          wx.error(function (res) {
            console.log('wx share fail:', res)
            reject(res)
          })
          wx.ready(function () {
            console.log('wx share config success')
            wx.onMenuShareTimeline({
              title: shareTitle, // 分享标题
              link: shareLink, // 分享链接
              imgUrl: shareImgUrl, // 分享图标
              success: options.successFun,
              cancel: options.cancelFun
            })
            // wx.hideMenuItems({
            //   menuList: [
            //     'menuItem:share:weiboApp'
            //   ], // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            // });
            wx.onMenuShareAppMessage({
              title: shareTitle, // 分享标题
              desc: shareDesc, // 分享描述
              link: shareLink, // 分享链接
              imgUrl: shareImgUrl, // 分享图标
              type: '', // 分享类型,music、video或link，不填默认为link
              dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
              success: options.successFun,
              cancel: options.cancelFun
            })
            showMenuList.length && wx.showOptionMenu(); // 可只显示发送朋友
            menuList.length && wx.hideMenuItems({
              menuList: menuList // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
            })
          })
          resolve(res)
        }).catch(function (error) {
          reject(error)
        })
      })
    },
    /* eslint-disable */
    forbidShare() {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', function () {
            WeixinJSBridge.call('hideOptionMenu')
          }, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', function () {
            WeixinJSBridge.call('hideOptionMenu')
          })
          document.attachEvent('onWeixinJSBridgeReady', function () {
            WeixinJSBridge.call('hideOptionMenu')
          })
        }
      } else {
        WeixinJSBridge.call('hideOptionMenu')
      }
    },
    // 用来初始禁止分享朋友圈
    forbidShare_friends: function () {
      this.initWXShare({
        hideMenuList: [
          'menuItem:share:timeline', // 分享到朋友圈
          'menuItem:share:appMessage',
          'menuItem:share:qq',
          'menuItem:share:weiboApp',
          'menuItem:share:QZone',
        ]
      })
    },
    // 用来初始禁止分享
    forbidShare_special: function () {
      this.initWXShare({
        hideMenuList: [
          'menuItem:share:timeline', // 分享到朋友圈
          'menuItem:share:appMessage',
          'menuItem:share:qq',
          'menuItem:share:weiboApp',
          'menuItem:share:QZone',
          'menuItem:copyUrl',
          'menuItem:openWithQQBrowser',
          'menuItem:openWithSafari'
        ]
      })
    }
  }
}
