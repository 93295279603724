<template>
    <div class="pg-guide-page">
        <div class="step-item">
            <img class="radio" :src="readio.checked">
            <span>购买成功</span>
        </div>
        <div class="step-line"></div>
        <div class="step-item">
            <img class="radio" :src="readio.default">
            <span>长按关注公众号，开始看课</span>
        </div>
        <div class="code-pane">
            <div class="code-border">
                <img v-if="qrcode" :src="qrcode" alt="公众号二维码">
            </div>
            <p class="tips">长按识别二维码，关注公众号</p>
        </div>
        <div class="title">
            <span>微信公众号福利</span>
        </div>
        <div class="col-pane">
            <div class="col-item">
                <img src="@assets/imgs/common/guidePage/icon_01.png">
                <p>在线学习</p>
            </div>
            <div class="col-item">
                <img src="@assets/imgs/common/guidePage/icon_02.png">
                <p>爆款课程</p>
            </div>
            <div class="col-item">
                <img src="@assets/imgs/common/guidePage/icon_03.png">
                <p>惊喜福利</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            readio: {
                default: require('@assets/imgs/icon/radio_default.png'),
                checked: require('@assets/imgs/icon/radio_checked.png')
            },
            qrcode: ''
        }
    },
    created() {
        this.qrcode = require(`@assets/imgs/common/guidePage/code_${this.$route.params.qrcode}.png`)
    }
}
</script>

<style lang="less">
.pg-guide-page {
    padding: 40px;
    .step-item {
        display: flex;
        line-height: 1;
        align-items: center;
        .radio {
            width: 40px;
            vertical-align: top;
        }
        span {
            font-size: 32px;
            margin-left: 32px;
        }
    }
    .step-line {
        width: 1px;
        height: 88px;
        background: #edeef2;
        margin: 12px 20px;
    }
    .code-pane {
        height: 592px;
        margin-top: 44px;
        box-shadow: 0px -6px 40px -4px rgba(77, 61, 153, 0.08);
        border-radius: 32px;
        overflow: hidden;
        .tips {
            color: #ff652f;
            font-size: 32px;
            text-align: center;
            margin-top: 48px;
        }
    }
    .code-border {
        width : 400px;
        height: 400px;
        padding: 13px;
        margin: 64px auto 0;
        border: 5px solid #ff652f;
        border-radius: 40px;
        box-sizing: border-box;
        img {
            width : 100%;
            height: 100%;
        }
    }
    .title {
        text-align: center;
        margin-top: 80px;
        span {
            position: relative;
        }
        span::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: calc(50% - 240px);
            width: 120px;
            height: 1px;
            background: #efeff1;
        }
        span::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: calc(50% - 240px);
            width: 120px;
            height: 1px;
            background: #efeff1;
        }
    }
    .col-pane {
        display: flex;
        margin-top: 32px;
        .col-item {
            flex: 1;
            text-align: center;
            img {
                width: 88px;
            }
            p {
                line-height: 1;
                color: #555b66;
                font-size: 24px;
                margin-top: 16px;
            }
        }
    }
}
</style>