module.exports = {
    22: '零基础 · 创意绘画课',
    101: '咕比美术启蒙课',
    463: 'VIPKID北美外教英语课',
    524: '3-6岁孩子爱学的英语课',
    525: 'VIPKID中外教英语课',
    535: '少儿数学思维小班直播课',
    558: '小猴思维+语文体验课',
    612: '咕比启蒙美术课',
    614: '咕比写字课',
    629: '咕比音乐课',
    631: '咕比围棋课',
}