<template>
    <div class="ft-address">
        <div class="form-item">
            <div class="label">{{info.name}}</div>
            <div class="value"
                :class="{'-placeholder':!values}"
                @click="onRegion">
                {{values || info.placeholder}}
            </div>
        </div>
        <div class="form-item">
            <div class="label">详细地址</div>
            <div class="value textarea">
                <textarea class="input" type="text" :value="detail" placeholder="请输入详细地址" @blur="onInput"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import Region from '@components/region/index.js'

export default {
    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            values: '', // 选择地区
            detail: '', // 详细地址
            code: '',
        }
    },
    methods: {
        onRegion() {
            Region({value:this.code}).then(({value}) => {
                let values = value.map(item => item.name)
                this.values = values.join(' / ')
                this.code = value[2] ? value[2].value : ''
                this.emitVal()
            })
        },
        onInput(e) {
            this.detail = e.target.value
            this.emitVal()
        },
        emitVal() {
            let values = this.values ? this.values.split(' / ') : []
            if (this.detail) values.push(this.detail)
            this.$emit('on-value', {value:values.join('-')})
        },
        // 校验数据
        validator() {
            if (!this.values) {
                this.$toast(this.info.placeholder)
                return false
            }
            if (!this.detail) {
                this.$toast('请输入详细地址')
                return false
            }
            return true
        }
    }
}
</script>

<style lang="less" scoped>
.ft-address {
    .form-item {
        display: flex;
        margin-top: 24px;
        .label {
            width: 116px;
            font-weight: 500;
            padding: 26px 0;
        }
        .value {
            flex: 1;
            display: flex;
            height: 96px;
            background: #f5f6f7;
            padding: 0 32px;
            margin-left: 24px;
            border-radius: 48px;
            align-items: center;
        }
        .textarea {
            height: 176px;
        }
    }
    .-placeholder {
        color: #8f959e;
    }
    .input {
        width: 100%;
        height: calc(100% - 52px);
        background: transparent;
        color: #1f2329;
        border: 0;
        padding: 0;
        resize: none;
    }
    .input::placeholder {
        color: #8f959e;
    }
}
</style>