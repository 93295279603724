<template>
    <div class="ft-refund-succ">
        <p class="title">提现提示</p>
        <p class="title-sub">退费申请已提交成功，点击下方按钮去提现至微信。</p>
        <div class="foot">
            <button class="btn submit" @click="onClick">去提现</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        channel: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        onClick() {
            window.location.href = process.env.VUE_APP_DENGTA + '/app/spa/dengta_h5/copartner/copartner_myprofit?channel=' + this.channel
        }
    }
}
</script>

<style lang="less" scoped>
.ft-refund-succ {
    padding: 40px;
    .title {
        line-height: 1;
        font-size: 36px;
        font-weight: 600;
        text-align: center;
    }
    .title-sub {
        line-height: 42px;
        margin-top: 40px;
    }
    .foot {
        display: flex;
        margin-top: 66px;
        justify-content: center;
        .btn {
            flex: 1;
            height: 80px;
            font-size: 28px;
            font-weight: 500;
            border: 0;
            border-radius: 40px;
        }
        .btn+ .btn {
            margin-left: 26px;
        }
        .submit {
            flex: none;
            width: 300px;
            background: #7559ff;
            color: #fff;
        }
    }
}
</style>