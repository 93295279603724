<template>
    <div class="ft-input"
        :class="{'-placeholder': !value}">
        <textarea class="input" type="text" :value="info.value" :placeholder="info.placeholder" @input="onInput"></textarea>
    </div>
</template>

<script>
export default {
    props: {
        info: {
            type: Object,
            default: () => ({}),
        }
    },
    methods: {
        onInput(e) {
            this.$emit('on-value', {value:e.target.value})
        }
    }
}
</script>

<style lang="less" scoped>
.ft-input {
    display: flex;
    height: 100%;
    align-items: center;
    .input {
        width: 100%;
        height: calc(100% - 52px);
        background: transparent;
        color: #1f2329;
        border: 0;
        padding: 0;
        resize: none;
    }
    .input::placeholder {
        color: #8f959e;
    }
}
</style>