<template>
  <div class="order-list">
    <div class="order-item box-border" v-for="(order, idx) in orderInfo" :key="idx">
      <div class="order-id flex-between">订单编号：{{order.code}}<div class="tips" v-if="order.orderStatus === 'PARTIAL_REFUNDED'">交易成功</div></div>
      <div class="course flex-start" v-for="course in order.orderItemInfos" :key="course.courseId">
        <img :src="course.courseCover" alt="" class="course-bg">
        <div class="right box-border d-flex flex-column flex-1"> 
          <div class="top">
            <div class="title d-flex flex-between">
              {{course.courseTitle}}
              <div class="price">¥ {{(order.price / 100).toFixed(2)}}</div>
            </div>
            <div class="invite-num" v-if="order.needInviteCount && !course.refundStatus">还需邀请：{{order.needInviteCount}}人</div>
            <div class="is-refund" v-if="course.refundStatus">已退款</div>
          </div>
          <div class="bottom flex-between" @click="toLearnCourse(order.orderStatus, order.needInviteCount, course.courseId)">
            交易时间：{{order.time | timeToDate}}<div class="button flex-center">
              <template v-if="course.refundStatus">
                <div v-for="discountInfo in order.orderDiscountInfos" :key="discountInfo.orderId">
                  <WxOpenLaunchWeapp :username="wxAppId" :path="`ftCourse/pages/course/v0/index?courseId=${course.courseId}&gChannel=${discountInfo.subjectId}`" :wxBtnStyle="wxBtnStyle" :wxBtnText="wxBtnText" class="to-select-course" v-if="discountInfo.type === 'GUBI_CHANNEL'">
                  </WxOpenLaunchWeapp>
                </div>
              </template>
              {{course.refundStatus | changeType(order.needInviteCount)}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const add0 = function(m) {
  return m < 10 ? '0' + m : m
}

/* eslint-disable */
import WxOpenLaunchWeapp from '@components/WxOpenLaunchWeapp'
export default {
  props: {
    orderInfo: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    WxOpenLaunchWeapp
  },
  filters: {
    timeToDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      var d = date.getDate()
      var h = date.getHours()
      var mm = date.getMinutes()
      var s = date.getSeconds()
      return y + '.' + add0(m) + '.' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s)
    },
    changeType(type, needInviteCount) {
      let txt = ''
      switch (type) {
        case false:
          txt = '去看课'
          if (needInviteCount > 0) {
            txt = '去拼团'
          }
          break
        case true:
          txt = '再次购买'
          break          
        default:
          break
      }
      return txt
    }
  },
  data () {
    return {
      wxAppId: 'gh_0a3dbde56d7b',
      wxBtnStyle: '',
      wxBtnText: ''
    }
  },
  mounted () {
    const winWidth = document.body.clientWidth
    this.wxBtnStyle = `.weapp-btn {
      display: block; width: ${(winWidth * 136) / 750}px; height: ${(winWidth * 56) / 750}px;
    }`
  },
  methods: {
    add0(m) {
      return m < 10 ? '0' + m : m
    },
    toLearnCourse(orderStatus, needInviteCount, courseId) {
      if (orderStatus === 'REFUNDED') return
      this.$router.push({
        path: '/platform/learnCourse',
        query: {
          courseId,
          showPoster: needInviteCount > 0
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
  .order-item {
    margin: 0 auto;
    padding: 32px 20px;
    width: 670px;
    background: #FFFFFF;
    border-radius: 16px;

    .order-id {
      margin-bottom: 32px;
      font-size: 24px;
      line-height: 24px;
      color: #272D38;

      .tips {
        font-size: 24px;
        font-style: normal;
        line-height: 24px;
        color: #FF652F;
      }
    }

    .course {
      height: 160px;

      & + .course {
        margin-top: 32px;
      }

      &-bg {
        flex-shrink: 0;
        margin-right: 24px;
        width: 160px;
        height: 160px;
        border-radius: 24px;
      }

      .right {
        justify-content: space-between;
        padding: 8px 0;
        height: 100%;
      }

      .title {       
        margin-bottom: 16px; 
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        color: #272D38;

        .price {
          font-weight: normal;
        }
      }

      .invite-num {
        font-size: 20px;
        line-height: 20px;
        color: #FF652F;
      }

      .is-refund {
        font-size: 20px;
        line-height: 20px;
        text-align: right;
        color: #555B66;
      }

      .bottom {
        align-items: flex-end;
        font-size: 20px;
        line-height: 20px;
        color: #9BA1AD;

        .button {
          position: relative;
          width: 136px;
          height: 56px;
          border-radius: 200px;
          background: #FF652F;
          font-size: 24px;
          line-height: 24px;
          color: #FFFFFF;
        }
      }
    }

    & + .order-item {
      margin-top: 32px;
    }

    .to-select-course {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>
