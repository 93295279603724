<template>
    <div class="ft-select"
        :class="{'-placeholder': !label}"
        @click="onPicker">
        {{label || info.placeholder}}
    </div>
</template>

<script>
import Picker from '@components/picker/index.js'

export default {
    props: {
        info: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            label: ''
        }
    },
    methods: {
        onPicker() {
            const {value, placeholder, options} = this.info
            Picker({
                value,
                title: placeholder,
                columns: options,
            }).then(({label, value}) => {
                this.label = label
                this.$emit('on-value', {value})
            })
        }
    }
}
</script>

<style lang="less" scoped>
.ft-select {
    display: flex;
    height: 100%;
    align-items: center;
    &.-placeholder {
        color: #8f959e;
    }
}
</style>